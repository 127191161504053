import React, { Component } from 'react';
import './Crossfade.css';

export class Crossfade extends Component {

  constructor(props) {
    super(props);
    this.state = {
      children: []
    };
  }

  push = (tag, component) => {
    if (!component) {
      this.setState({
        children: []
      })
    } else {
      const found = this.state.children.find(x => x.tag === tag)
      if (found) {
        found.component = component
        this.forceUpdate()
      } else {
        this.state.children.unshift({tag, component})
        this.forceUpdate()
        this.timer = setTimeout(() => {
          this.timer = null
          if (this.state.children > 1) {
            this.state.children.pop()
            this.forceUpdate()
          }
        }, 500)
      }
    }
  }
  
  componentDidMount() {
    this.props.onCreate(this)
  }

  render() {
    return  this.state.children.map((x, i) => {
      const { component } = x
      if (i === 0) {
        return <div className={`fade-component fade-component-visible`}>
                 {component}
               </div>
      }
      return  <div className={`fade-component`}>
        {component}
      </div>
    })
  }
  
  renderOld() {
    const [fadeIn, fadeOut] = this.state.children.map(x => x.component)
    return (
        <div className="crossfade-wrapper">
          {fadeIn && (
            <div className={`fade-component fade-component-visible`}>
              {fadeIn}
            </div>
          )}
          {fadeOut && (
            <div className={`fade-component`}>
              {fadeOut}
            </div>
          )}
        </div>
    );
  }
}


