import React, { Component } from 'react'
import Popup from 'reactjs-popup';
import { DeleteButton, SimpleButton } from '../ChatGPT'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import Undo from '../../assets/Icons/Redo.svg'
import Redo from '../../assets/Icons/Undo.svg'
import Cut from '../../assets/Icons/Share.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Edit from '../../assets/Icons/Edit.svg'
import Paste from '../../assets/Icons/Paste.svg'
import './EditMenu.css'

export class EditMenu extends Component {
  setRef = ref => {
    this.ref = ref
  }
  setPopupRef = popupRef => {
    this.popup = popupRef
  }
  render() {
    const { edit, cut, copy, paste, undo, redo, trash } = this.props
    const trigger = <div id={'editMenuTrigger'}><SimpleButton icon={MenuDown} action={() => {}}/></div>
    const onOpen = (isOpen) => {}
    let maxHeight
    let position = "bottom right"
    const ref = this.ref
    if (ref) {
      const rect = ref.getBoundingClientRect()
      if (rect.bottom  > window.innerHeigt * 0.75) {
        position = "top right"
      }
    }
    let className = 'editMenu'
    if (this.props.className) {
      className += ' ' + this.props.className
    }
    const renderMenu = (close) => {
      const callAction = action => async () => {
        await action()
        close()
      }
      return <div className={className}>
               {edit && <SimpleButton icon={Edit} label='Edit' action={callAction(edit)}/>}
               {cut && <SimpleButton icon={Cut} label='Cut' action={callAction(cut)}/>}
               {copy && <SimpleButton icon={Copy} label='Copy' action={callAction(copy)}/>}
               {paste && <SimpleButton icon={Paste} label='Paste' action={callAction(paste)}/>}
               {undo && <SimpleButton icon={Undo} label='Undo' action={callAction(undo)}/>}
               {redo && <SimpleButton icon={Redo} label='Redo' actio={callAction(redo)}/>}
               {trash && <DeleteButton label='Delete' trash={trash}/>}
      </div>

   }
    return <div className='editMenuPopup' ref={this.setRef}>
             <Popup ref={this.setPopupRef} closeOnDocumentClick trigger={trigger} position={position}>
               {close => renderMenu(close)}
             </Popup>
           </div>
  }
}

