import React from 'react'
//import ReactDOM from 'react-dom/client'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { BnClient } from './components/Client'
import './index.css'

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render ( <BrowserRouter>
                { <BnClient /> }
              </BrowserRouter>)




