// Open (or create) the IndexedDB database
function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("fineTuningMetrics", 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("metricsStore")) {
        db.createObjectStore("metricsStore", { keyPath: "jobId" });
      }
    };

    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
}

// Store metrics by job ID
export async function storeMetrics(jobId, metricsArray) {
  const db = await openDatabase();
  const transaction = db.transaction("metricsStore", "readwrite");
  const store = transaction.objectStore("metricsStore");

  const metricsData = { jobId, metrics: metricsArray };

  return new Promise((resolve, reject) => {
    const request = store.put(metricsData);
    request.onsuccess = () => resolve("Metrics stored successfully");
    request.onerror = (event) => reject(event.target.error);
  });
}

// Retrieve metrics by job ID
export async function getMetrics(jobId) {
  const db = await openDatabase();
  const transaction = db.transaction("metricsStore", "readonly");
  const store = transaction.objectStore("metricsStore");

  return new Promise((resolve, reject) => {
    const request = store.get(jobId);
    request.onsuccess = (event) => {
      if (event.target.result) {
        resolve(event.target.result.metrics);
      } else {
        resolve(null); // Return null if not found
      }
    };
    request.onerror = (event) => reject(event.target.error);
  });
}
