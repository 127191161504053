import React, { Component } from 'react'
import { isSafari, isChrome, isMobile, isDesktop } from '../../classes/Platform.js'


/**
 * Easing function: easeInOutCubic
 * @param {number} t - Progress of the animation (0 to 1)
 * @returns {number} - Eased progress
 */
function easeInOutCubic(t) {
  return t < 0.5
    ? 4 * t * t * t
    : 1 - Math.pow(-2 * t + 2, 3) / 2;
}

/**
 * Finds the closest scrollable ancestor of an element.
 * @param {Element} element - The target element.
 * @returns {Element|Window} - The nearest scrollable ancestor or the window if none found.
 */
function getScrollableParent(element) {
  if (!(element instanceof Element)) {
    throw new Error('The provided target is not a DOM element.');
  }

  const overflowRegex = /(auto|scroll|hidden)/;

  let parent = element.parentElement;

  while (parent) {
    const style = window.getComputedStyle(parent);
    const overflowY = style.overflowY;
    const overflowX = style.overflowX;

    if (overflowRegex.test(overflowY) || overflowRegex.test(overflowX)) {
      // Check if the element is scrollable
      if (parent.scrollHeight > parent.clientHeight || parent.scrollWidth > parent.clientWidth) {
        return parent;
      }
    }
    parent = parent.parentElement;
  }

  // If no scrollable parent found, return window
  return window;
}

/**
 * Calculates the position of an element relative to a container.
 * @param {Element} target - The target element.
 * @param {Element|Window} container - The scrollable container.
 * @returns {{ top: number, left: number }} - The top and left positions.
 */
function getRelativePosition(target, container) {
  const src = target.getBoundingClientRect()
  const dst = (container instanceof Element) ? container.getBoundingClientRect() : {
    top: 0,
    left: 0
  }
  return {
    top: src.top - dst.top,
    left: src.left - dst.left
  }
  let posX = 0, posY = 0;
  let el = target;

  while (el && el !== container && el.offsetParent) {
    posX += el.offsetLeft;
    posY += el.offsetTop;
    el = el.offsetParent;
  }

  return { top: posY, left: posX };
}

/**
 * Custom smooth scroll into view function with `block` and `inline` alignment options
 * @param {Element} target - The target element to scroll into view
 * @param {Object} options - Optional parameters
 * @param {number} options.duration - Duration of the scroll in ms
 * @param {number} options.offset - Additional offset in pixels
 * @param {function} options.easing - Easing function
 * @param {string} options.block - Vertical alignment: 'start', 'center', 'end', 'nearest'
 * @param {string} options.inline - Horizontal alignment: 'start', 'center', 'end', 'nearest'
 * @returns {Promise} - Resolves when scrolling is complete
 */
function customScrollIntoView(target, options = {}) {
  ////debugger
  return new Promise((resolve, reject) => {
    if (!(target instanceof Element)) {
      reject(new Error('Target must be a DOM element.'));
      return;
    }

    const {
      duration = 500,
      offset = 0,
      easing = easeInOutCubic,
      block = 'start',   // 'start', 'center', 'end', 'nearest'
      inline = 'start',  // 'start', 'center', 'end', 'nearest'
    } = options;

    const scrollableParent = getScrollableParent(target);
    if (!scrollableParent) {
      console.warn("scrollable parent not found", scrollableParent)
      return
    }

    // Calculate the target's position relative to the scrollable parent
    const relativePos = getRelativePosition(target, scrollableParent);
    console.log({relativePos})
    const targetHeight = target.offsetHeight;
    const targetWidth = target.offsetWidth;

    let destinationY, destinationX;

    // Vertical Alignment (block)
    switch (block) {
      case 'start':
        destinationY = relativePos.top + offset;
        break;
      case 'center':
        destinationY = relativePos.top - (scrollableParent === window ? window.innerHeight / 2 - targetHeight / 2 : scrollableParent.clientHeight / 2 - targetHeight / 2) + offset;
        break;
      case 'end':
        destinationY = relativePos.top + targetHeight - (scrollableParent === window ? window.innerHeight : scrollableParent.clientHeight) + offset;
        break;
      case 'nearest':
        const parentRect = scrollableParent === window ? { top: 0, bottom: window.innerHeight } : scrollableParent.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();

        if (scrollableParent === window) {
          if (targetRect.top < 0) {
            destinationY = window.pageYOffset + targetRect.top + offset;
          } else if (targetRect.bottom > window.innerHeight) {
            destinationY = window.pageYOffset + targetRect.bottom - window.innerHeight + offset;
          } else {
            destinationY = window.pageYOffset; // No scrolling needed
          }
        } else {
          if (targetRect.top < parentRect.top) {
            destinationY = scrollableParent.scrollTop + targetRect.top - parentRect.top + offset;
          } else if (targetRect.bottom > parentRect.bottom) {
            destinationY = scrollableParent.scrollTop + targetRect.bottom - parentRect.bottom + offset;
          } else {
            destinationY = scrollableParent.scrollTop; // No scrolling needed
          }
        }
        break;
      default:
        destinationY = relativePos.top + offset;
    }
    
    // Horizontal Alignment (inline)
    switch (inline) {
      case 'start':
        destinationX = relativePos.left + offset;
        break;
      case 'center':
        destinationX = relativePos.left - (scrollableParent === window ? window.innerWidth / 2 - targetWidth / 2 : scrollableParent.clientWidth / 2 - targetWidth / 2) + offset;
        break;
      case 'end':
        destinationX = relativePos.left + targetWidth - (scrollableParent === window ? window.innerWidth : scrollableParent.clientWidth) + offset;
        break;
      case 'nearest':
        const parentRectInline = scrollableParent === window ? { left: 0, right: window.innerWidth } : scrollableParent.getBoundingClientRect();
        const targetRectInline = target.getBoundingClientRect();

        if (scrollableParent === window) {
          if (targetRectInline.left < 0) {
            destinationX = window.pageXOffset + targetRectInline.left + offset;
          } else if (targetRectInline.right > window.innerWidth) {
            destinationX = window.pageXOffset + targetRectInline.right - window.innerWidth + offset;
          } else {
            destinationX = window.pageXOffset; // No scrolling needed
          }
        } else {
          if (targetRectInline.left < parentRectInline.left) {
            destinationX = scrollableParent.scrollLeft + targetRectInline.left - parentRectInline.left + offset;
          } else if (targetRectInline.right > parentRectInline.right) {
            destinationX = scrollableParent.scrollLeft + targetRectInline.right - parentRectInline.right + offset;
          } else {
            destinationX = scrollableParent.scrollLeft; // No scrolling needed
          }
        }
        break;
      default:
        destinationX = relativePos.left + offset;
    }
    console.log("destination y", destinationY)
    // Clamp destination positions to valid scroll ranges
    const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
    /*
    if (scrollableParent === window) {
      const maxScrollY = document.documentElement.scrollHeight - window.innerHeight;
      const maxScrollX = document.documentElement.scrollWidth - window.innerWidth;

      destinationY = clamp(destinationY, 0, maxScrollY);
      destinationX = clamp(destinationX, 0, maxScrollX);
    } else {
      const maxScrollY = scrollableParent.scrollHeight - scrollableParent.clientHeight;
      const maxScrollX = scrollableParent.scrollWidth - scrollableParent.clientWidth;

      destinationY = clamp(destinationY, 0, maxScrollY);
      destinationX = clamp(destinationX, 0, maxScrollX);
      }
    */
    // Current scroll positions
    let startY, startX;
    if (scrollableParent === window) {
      startY = window.pageYOffset;
      startX = window.pageXOffset;
    } else {
      startY = scrollableParent.scrollTop;
      startX = scrollableParent.scrollLeft;
    }
    destinationY += startY
    destinationX += startX

    const distanceY = destinationY - startY;
    const distanceX = destinationX - startX;
    let startTime = null;

    console.log({startY, destinationY})
    
    // Animation loop
    function animation(currentTime) {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1); // Ensure progress doesn't exceed 1

      const easedProgress = easing(progress);
      const currentY = startY + distanceY * easedProgress;
      const currentX = startX + distanceX * easedProgress;

      if (scrollableParent === window) {
        window.scrollTo(currentX, currentY);
      } else {
        scrollableParent.scrollTo(currentX, currentY);
      }

      if (timeElapsed < duration) {
        window.requestAnimationFrame(animation);
      } else {
        // Ensure we reach the destination
        if (scrollableParent === window) {
          window.scrollTo(destinationX, destinationY);
        } else {
          scrollableParent.scrollTo(destinationX, destinationY);
        }
        resolve();
      }
    }

    // Start the animation
    window.requestAnimationFrame(animation);
  });
}

export class BottomAlignedScroll extends React.Component {
  constructor(props) {
    super(props);
    this.scrollBottom = 0;
    this.isUserScrolling = false;
    this.isAtTop = true;
    this.isAtBottom = true;
    this.isAutoScrollEnabled = true;
    this.previousScrollHeight = 0;
    this.lastClientHeight = 0;
    this.lastScrollTop = 0;
    // New state tracking
    this.timeoutIds = new Set();
    this.resizeQueue = [];
    this.pendingResize = null;
    this.rafId = null;

    this.scrollRef = React.createRef();
    this.contentRef = React.createRef();
    this.viewportObserver = null;
    this.contentObserver = null;
  }

  componentDidMount() {
    try {
      this.viewportObserver = new ResizeObserver(entries => {
        if (this.rafId) cancelAnimationFrame(this.rafId);
        this.rafId = requestAnimationFrame(() => {
          this.handleViewportResize(entries);
          this.rafId = null;
        });
      });
      
      this.contentObserver = new ResizeObserver(entries => {
        if (this.rafId) cancelAnimationFrame(this.rafId);
        this.rafId = requestAnimationFrame(() => {
          this.handleContentResize(entries);
          this.rafId = null;
        });
      });
      
      const ref = this.scrollRef.current;
      if (ref) {
        this.viewportObserver.observe(ref);
        this.lastScrollTop = ref.scrollTop;
        this.previousScrollHeight = ref.scrollHeight;
        this.lastClientHeight = ref.clientHeight;
      }
      if (this.contentRef.current) {
        this.contentObserver.observe(this.contentRef.current);
      }
      this.checkScrollBoundaries();
    } catch (e) {
      console.error('Error setting up observers:', e);
    }
  }

  setUserScrolling = value => {
    if (value !== this.isUserScrolling) {
      //debugger
      this.isUserScrolling = value
    }
  }

  setAutoscrollEnabled = value => {
    if (value !== this.isAutoscrollEnabled) {
      //debugger
      this.isAutoscrollEnabled = value
    }
  }


  componentWillUnmount() {
    if (this.viewportObserver) {
      this.viewportObserver.disconnect();
    }
    if (this.contentObserver) {
      this.contentObserver.disconnect();
    }
    this.timeoutIds.forEach(id => clearTimeout(id));
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
    }
  }

  inWindowUpdate = false;
  
  onScrolledToTop = () => {
    if (this.offset > 0) {
      if (true) {
        console.log("onScrolledToTop", this.offset, this.scrollRef.current.scrollHeight);
        return this.props.selectItemIndex(this.offset-1);
      }
    } else {
      if (this.props.onScrolledToTop) {
        this.props.onScrolledToTop().then(({offset, limit, length}) => {
          if (offset === 0) {
            // we really hit top
          } else {
            this.offset = offset
            this.limit = limit
            this.props.selectItemIndex(offset-1)
          }
        })
      } else {
        // we really hit top
      }
    }
  }

  onScrolledToBottom = () => {
    if (this.limit < this.props.items.length) {
      this.inSelectItem = true
      this.props.selectItemIndex(this.limit+1).then(() => {
        this.inSelectItem = false
      });
    } else {
      if (this.props.onScrolledToBottom) {
        this.props.onScrolledToBottom().then(({offset, limit, length}) => {
          if (limit >= this.props.items.length) { // we really hit bottom
            this.setAutoscrollEnabled(true);
          } else {
            this.setAutoscrollEnabled(false)
            this.offset = offset
            this.limit = limit
            this.props.selectItemIndex(this.limit+1);
          }
        })
      } else {
        this.setAutoscrollEnabled(true);
      }
    }
  }

  handleViewportResize = (entries) => {
    const ref = this.scrollRef.current;
    if (!ref) return;
    const delta = ref.clientHeight - this.lastClientHeight
    this.lastClientHeight = ref.clientHeight;
    console.log("viewportResized", delta)
    this.updateScroll();
    this.checkScrollBoundaries();
  }

  setScrollTopNow = newTop => {
    const ref = this.scrollRef.current;
    if (!ref) return;
    ref.scrollTop = Math.max(0, Math.min(newTop, ref.scrollHeight - ref.clientHeight));
    this.scrollBottom = ref.scrollTop + ref.clientHeight
  }

  setScrollTop = async newTop => {
    const ref = this.scrollRef.current;
    if (!ref) return;
    return new Promise(resolve => {
      console.log("set scrollTop", newTop)
      this.lastScrollTop = newTop
      this.inUpdate = true
      if (this.rafId) cancelAnimationFrame(this.rafId);
      this.rafId = requestAnimationFrame(() => {
        ref.scrollTop = Math.max(0, Math.min(newTop, ref.scrollHeight - ref.clientHeight));
        this.rafId = null;
        setTimeout(() => {
          this.checkScrollBoundaries();
          this.inUpdate = false
          resolve()
        })
      });
    })
  }

  handleContentResize = (entries) => {
    // Queue this resize operation
    this.resizeQueue.push(() => {
      return new Promise(resolve => {
        const ref = this.scrollRef.current;
        if (!ref) {
          resolve();
          return;
        }

        const delta = ref.scrollTop - this.lastScrollTop;
        const delta2 = ref.scrollHeight - this.previousScrollHeight;
        const { isUserScrolling, isAutoScrollEnabled } = this
        console.log("content resized", delta2, { isAutoScrollEnabled, isUserScrolling })
        this.previousScrollHeight = ref.scrollHeight;

        if (isAutoScrollEnabled && (!isUserScrolling || (performance.now() - this.lastDeltaYTime) > 300)) {
          this.scrollToBottom()
        } else if (false) {
          if (this.scrollBottom <= 1) {
            this.scrollBottom = Math.max(0, this.scrollBottom + delta2);
            this.setScrollTop(this.computeScrollTop());
          } else {
            this.setScrollTop(ref.scrollTop + delta2);
          }
          this.checkMomentumScroll()
        }
        if (this.rafId) cancelAnimationFrame(this.rafId);
        const run = () => {
          resolve();
          this.rafId = null;
        }
        if (true) {
          run()
        } else {
          this.rafId = requestAnimationFrame(run);
        }
      });
    });
    this.processResizeQueue();
  }

  checkMomentumScroll = () => {
    const ref = this.scrollRef.current;
    if (!ref) return;
    const lastDeltaY = this.lastDeltaY
    const timeDelta = (performance.now() - this.lastDeltaYTime)
    console.log({lastDeltaY, timeDelta})
    if (this.lastDeltaY && timeDelta < 600) {
      this.handleMomentumScroll(ref, this.lastDeltaY, 300);
      this.lastDeltaY = 0;
    }
  }

  processResizeQueue = async () => {
    if (this.pendingResize) return;
    while (this.resizeQueue.length > 0) {
      const nextResize = this.resizeQueue.shift();
      this.pendingResize = nextResize;
      await nextResize();
      this.pendingResize = null;
    }
    console.log("handled resize")
    const then = this.onResize;
    this.onResize = null;
    if (then) {
      then()
    }
  }

  forceParentUpdate = () => {
    if (this.parent) {
      this.parent.forceUpdate()
    }
  }

  handleMomentumScroll = (targetElement, initialDeltaY, duration) => {
    if (!targetElement) return;
    let clamp = 15
    initialDeltaY = Math.max(-1*clamp, Math.min(clamp, initialDeltaY));
    const steps = 20;
    const frameDelay = Math.round(duration / steps / (1000 / 60)); // Frames between steps
    let currentDeltaY = initialDeltaY;
    let frameCount = 0;
    
    const animate = () => {
      if (!targetElement || Math.abs(currentDeltaY) < 1) return;
      
      frameCount++;
      if (frameCount >= frameDelay) {
        frameCount = 0;
        targetElement.scrollTop += currentDeltaY;
        currentDeltaY *= 0.90; // Keep original decay
        requestAnimationFrame(animate);
      } else {
        requestAnimationFrame(animate);
      }
    };
    
    requestAnimationFrame(animate);
  }
  

  handleMomentumScrollOld = (targetElement, initialDeltaY, duration) => {
    if (!targetElement) return;
    console.log("handleMomentumScroll: ", initialDeltaY)
    initialDeltaY = Math.max(-30, Math.min(30, initialDeltaY));
    const steps = 20;
    const framesPerStep = duration / steps / (1000 / 60); // Convert to frames at 60fps
    const totalFrames = steps * framesPerStep;
    const weight = Math.pow(0.9, steps/totalFrames); 
    let currentDeltaY = initialDeltaY;
    let frameCount = 0;
    
    const animate = () => {
      if (!targetElement || Math.abs(currentDeltaY) < 1) return;
      
      frameCount++;
      if (frameCount >= framesPerStep) {
        frameCount = 0;
        this.setScrollTopNow(targetElement.scrollTop + currentDeltaY)
        currentDeltaY *= weight;
        requestAnimationFrame(animate);
      } else {
        requestAnimationFrame(animate);
      }
    }
    requestAnimationFrame(animate)
  }


  checkScrollBoundaries = () => {
    const ref = this.scrollRef.current;
    if (!ref) return;

    const { onScrolledToTop, onScrolledToBottom } = this;
    const prevAtTop = this.isAtTop;
    const prevAtBottom = this.isAtBottom;

    this.isAtTop = ref.scrollTop <= 0;
    this.isAtBottom = Math.abs(ref.scrollHeight - ref.scrollTop - ref.clientHeight) <= 1;

    if (this.isAtTop !== prevAtTop || this.isAtBottom !== prevAtBottom) {
      if (this.isAtTop !== prevAtTop && this.isAtTop) {
        if (onScrolledToTop && this.isAtTop) {
          return onScrolledToTop();
        }
      }
      if (this.isAtBottom !== prevAtBottom && this.isAtBottom) {
        if (onScrolledToBottom && this.isAtBottom) {
          return onScrolledToBottom();
        }
      }
    }
  }

  pushScrollTop = () => {
    const { isAtTop, isAtBottom, isUserScrolling, isAutoScrollEnabled } = this
    this.saved = { isAtTop, isAtBottom, isUserScrolling, isAutoScrollEnabled}
    const ref = this.scrollRef.current;
    this.savedScrollTop = ref.scrollTop
    this.scrollDisabled = true
    console.log("pushScrollTop", {scrollTop: this.savedScrollTop, scrollBottom: this.scrollBottom})
  }

  popScrollTop = () => {
    const ref = this.scrollRef.current;
    if (!ref) {
      return
    }
    for (const id in this.saved) {
      this[id] = this.saved[id]
    }
    this.wasAtTop = this.isAtTop
    this.wasAtBottom = this.isAtBottom
    const delta = ref.scrollTop - this.savedScrollTop
    console.log("popScrollTop", delta, {scrollTop: ref.scrollTop, scrollBottom: this.scrollBottom})
    this.scrollBottom = ref.scrollTop + ref.clientHeight
    console.log("popScrollTop'", {scrollTop: ref.scrollTop, scrollBottom: this.scrollBottom})
    for (const id in this.saved) {
      this[id] = this.saved[id]
    }
    this.lastScrollTop = ref.scrollTop
    this.wasAtTop = this.isAtTop
    this.wasAtBottom = this.isAtBottom
    this.scrollDisabled = false
  }

  scrollDisabled = false
  handleScroll = () => {
    if (this.scrollDisabled) {
      return
    }
    const ref = this.scrollRef.current;
    if (!ref) return;
    const currentScrollTop = ref.scrollTop;
    const scrollingUp = currentScrollTop < this.lastScrollTop;
    this.wasUserScrollingUp = this.isUserScrolling && scrollingUp
    const delta = currentScrollTop - this.lastScrollTop
    this.lastDeltaY = delta
    this.lastDeltaYTime = performance.now()
    if (delta === 0) return
    console.log("handleScroll", delta)
    this.lastScrollingUp = scrollingUp;
    this.lastScrollTop = currentScrollTop;
    if (scrollingUp && this.isUserScrolling) {
      this.setAutoscrollEnabled(false)
    }
    const newScrollBottom = ref.scrollHeight - currentScrollTop - ref.clientHeight;
    this.setUserScrolling(true)
    if (Math.abs(newScrollBottom - this.scrollBottom) > 1) {
      this.scrollBottom = newScrollBottom;
      this.checkScrollBoundaries();
    } else {
      this.checkScrollBoundaries();
    }
  }

  scrollToBottom = () => {
    this.scrollBottom = 0;
    //debugger
    if (!this.isAutoScrollEnabled) {
      this.setAutoscrollEnabled(true)
    }
    this.setUserScrolling(false)
    this.updateScroll();
  }

  scrollToTop = () => {
    const ref = this.scrollRef.current;
    if (!ref) return;
    this.scrollBottom = ref.scrollHeight - ref.clientHeight;
    this.setAutoscrollEnabled(false);
    this.setUserScrolling(false);
    this.updateScroll();
  }

  computeScrollTop = () => {
    const ref = this.scrollRef.current;
    if (!ref) return 0;
    return Math.max(0, ref.scrollHeight - this.scrollBottom - ref.clientHeight);
  }

  updateScrollIfUp = () => {
    if (this.wasUserScrollingUp) {
      this.updateScroll()
    }
    this.checkMomentumScroll()
  }

  updateScroll = () => {
    const ref = this.scrollRef.current;
    if (!ref) return;
    if (this.scrollBottom < 0) {
      this.scrollBottom = ref.scrollHeight - ref.offsetHeight;
    }
    if (this.isAutoScrollEnabled) {
      this.scrollBottom = 0;
    }
    const newScrollTop = this.computeScrollTop();
    this.setScrollTopNow(newScrollTop);
    console.log('updateScroll', {scrollTop: ref.scrollTop, scrollBotto: this.scrollBottom})
    this.lastScrollTop = ref.scrollTop;
  }

  offset = 0;
  limit = this.props.pageSize;
  lastDeltaY = 0;
  lastDeltaYTime = 0

  lastWheel = 0
  lastTouchY = 0
  
  handleScrollMovement = (deltaY) => {
    const scrollingUp = deltaY < 0;
    this.lastDeltaYTime = performance.now()
    this.lastDeltaY = deltaY
    if (scrollingUp && this.isAutoScrollEnabled && this.isUserScrolling) {
      this.isAutoScrollEnabled = false;
    }
  }
  
  handleWheel = (e) => {
    this.handleScrollMovement(e.deltaY);
  }

  handleTouchStart = e => {
    const touchY = e.touches[0].clientY;
    this.lastTouchY = touchY
    this.touching = true
  }

  handleTouchEnd = e => {
    this.touching = false
  }

  handleTouchMove = (e) => {
    const touchY = e.touches[0].clientY;
    const deltaY = this.lastTouchY - touchY;
    this.lastTouchY = touchY;
    this.handleScrollMovement(deltaY);
  }

  render() {
    const { className = '' } = this.props;

    const fitWindow = (items, selectedItemIndex, windowSize, previousWindowStart, previousWindowLimit) => {
      const totalItems = items.length;
      if (selectedItemIndex === -1) {
        return {start: previousWindowStart, limit: previousWindowLimit};
      }
      const newStart = Math.max(0, Math.min(selectedItemIndex - Math.floor(windowSize / 2), totalItems - windowSize));
      const newLimit = Math.min(totalItems, newStart + windowSize);
      
      const overlapStart = Math.max(previousWindowStart, newStart);
      const overlapEnd = Math.min(previousWindowLimit, newLimit);
      
      if (overlapEnd > overlapStart) {
        return {
          start: Math.min(previousWindowStart, newStart),
          limit: Math.max(previousWindowLimit, newLimit),
        };
      }
      return { start: newStart, limit: newLimit };
    }

    const { pageSize } = this.props;
    const selectedItemIndex = this.props.selectedItemIndex;
    let { start, limit } = fitWindow(this.props.items, selectedItemIndex, pageSize, this.offset, this.limit);
    
    this.offset = start;
    this.limit = limit;
    const window = this.props.items.slice(start, limit);
    console.log('rendering items')
    return (
      <div 
        ref={this.scrollRef}
        className={`messageScroller ${className}`}
        onScroll={this.handleScroll}
        /*
        onWheel={this.handleWheel}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        */
      >
        <div className="messageScrollerContent" ref={this.contentRef}>
          {this.props.renderItems(window)}
        </div>
      </div>
    );
  }
}

export class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: this.pageSize
    };
  }

  selectedItemIndex = -1;
  pageSize = 8;

  checkMessageVisibility = (id) => {
    if (!this.scroller?.scrollRef?.current) return false;
    
    const element = this.scroller.scrollRef.current.querySelector(`[data-message-id="${id}"]`);
    if (!element) return false;
    
    const rect = element.getBoundingClientRect();
    const parentRect = this.scroller.scrollRef.current.getBoundingClientRect();
    return rect.top >= parentRect.top && rect.bottom <= parentRect.bottom;
  }

  scrollToTopMessage = () => this.scrollToMessage(this.props.messages[0])

  scrollToBottomMessage = () => this.scrollToMessage(this.props.messages[this.props.messages.length-1])
  
  scrollToMessage = m => {
    if (!m) return
    return new Promise((resolve) => {
      const id = m.id;
      console.log(`Attempting to scroll to message ${id}`);
      const ms = this.props.messages;
      for (let index = 0; index < ms.length; index++) {
        const m1 = ms[index];
       if (m1.id === id) {
          // Capture scroll state BEFORE any changes
         if (!this.scroller) return
         this.scroller.setUserScrolling(false)
         this.scroller.pushScrollTop()
         this.selectedItemIndex = index;
         console.log(`Found message at index ${index}, current offset=${this.scroller.offset}, limit=${this.scroller.limit}`);
         this.scroller.onResize = null;
          if (index >= this.scroller.offset && 
              index < this.scroller.limit) {
            const element = document.querySelector(`[data-message-id="${id}"]`);
            if (element) {
              console.log('element found 1')
              this.forceUpdate(async () => {
                // Restore original state before scrolling
                await customScrollIntoView(element, { behavior: 'smooth', block: 'start' });
                this.scroller.popScrollTop()
                return resolve();
              })
            }
          }
          this.scroller.onResize = async () => {
            console.log(`onResize called for message ${id}`);
            this.scroller.onResize = null
            this.forceUpdate(async () => {
              // Restore original state before scrolling
              const element = document.querySelector(`[data-message-id="${id}"]`);
              if (element) {
                console.log('element found 2')
                await customScrollIntoView(element,{ behavior: 'smooth', block: 'start' })
                this.scroller.popScrollTop()
              } else {
                console.log("element not found")
              }
              resolve();
            });
          };
          this.forceUpdate();
          return;
        }
      }
      resolve();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedMessage && this.props.selectedMessage !== prevProps.selectedMessage) {
      if (this.props.selectedMessage) {
        const m = this.props.selectedMessage;
        this.scrollToMessage(m);
      }
    }
  }

  componentDidMount() {
    this.props.onCreate(this);
  }

  setScroller = ref => {
    this.scroller = ref;
    if (ref) {
      ref.parent = this
    }
  }

  enableAutoscroll = () => {
    if (this.scroller) this.scroller.setAutoscrollEnabled(true);
  }

  scrollToBottom = () => {
    this.scrollToMessage(this.props.messages[this.props.messages.length-1]).then(() => this.scroller && this.scroller.scrollToBottom())
  }

  scrollToTop = () => {
    this.scrollToMessage(this.props.messages[0]).then(() => this.scroller && this.scroller.scrollToTop())
  }

  selectItemIndex = index => {
    return new Promise(resolve => {
      this.selectedItemIndex = index;
      this.forceUpdate(resolve)
    })
  }

  onScrolledToBottom = async () => {
    let window
    if (this.props.onScrolledToBottom) {
      const {selectedItemIndex, length} = await this.props.onScrolledToBottom()
      this.selectedItemIndex = selectedItemIndex
      window = { selectedItemIndex, length }
    } else {
      window = {
        selectedItemIndex: this.selectedItemIndex,
        length: this.props.messages.length
      }
    }
    return {
      offset: window.selectedItemIndex,
      limit: window.selectedItemIndex + this.pageSize,
      length: window.length
    }
  }

  onScrolledToTop = async () => {
    let window
    if (this.props.onScrolledToTop) {
      const {selectedItemIndex, length} = await this.props.onScrolledToTop()
      this.selectedItemIndex = selectedItemIndex
      window = { selectedItemIndex, length }
    } else {
      window = {
        selectedItemIndex: this.selectedItemIndex,
        length: this.props.messages.length
      }
    }
    return {
      offset: window.selectedItemIndex,
      limit: window.selectedItemIndex + this.pageSize,
      length: window.length
    }
  }


  render() {
    const messages = this.props.messages;
    const marginClass = this.props.marginClass
    const containerClass = this.props.containerClass
    return (
      <div key={this.props.key} className={marginClass}>
        <BottomAlignedScroll
          /*
          onScrolledToBottom={this.onScrolledToBottom}
          onScrolledToTop={this.onScrolledToTop}
          */
          ref={this.setScroller}
          items={messages}
          pageSize={11}
          selectedItemIndex={this.selectedItemIndex}
          selectItemIndex={this.selectItemIndex}
          clearSelectedItem={() => {
            this.selectedItemIndex = -1;
            this.props.selectMessage(null);
          }}
          renderItems={messages => {
            return (
              <div key='chatMessagesContainer' className={containerClass}>
                {this.props.renderMessages(messages, (message) => ({
                  'data-message-id': message.id
                }))}
              </div>
            );
          }}
        />
      </div>
    );
  }
}
