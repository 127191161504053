import React from 'react';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer 
} from 'recharts';
import './LossGraph.css'

export const LossGraph = ({ metrics, model, dataset }) => {
  return (
    <div className='lossGraphContainerStyle'>
      {model}
      {dataset && <div className='lossGraphDataset'>{ dataset.name}</div>}
      <h2 className='lossGraphTitleStyle'>Training Loss Over Time</h2>
      <ResponsiveContainer width="100%" height="90%">
        <LineChart
          throttleDelay={3*16.666}
          data={metrics}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke="#ffffff20"
          />
          <XAxis 
            dataKey="step" 
            stroke="#fff"
            label={{ 
              value: 'Training Step', 
              position: 'bottom', 
              offset: 0,
              fill: '#fff'
            }}
          />
          <YAxis
            stroke="#fff"
            domain={['auto', 'auto']}
            label={{ 
              value: 'Loss', 
              angle: -90, 
              position: 'insideLeft',
              offset: 10,
              fill: '#fff'
            }}
          />
          <Tooltip 
            formatter={(value) => value.toFixed(4)}
            labelFormatter={(label) => `Step ${label}`}
            contentStyle={{
              backgroundColor: '#1a2b32',
              border: '1px solid #fff',
              color: '#fff'
            }}
          />
          <Line
            type="monotone"
            dataKey="train_loss"
            stroke="#4ade80"
            stroke="#2563eb"
            dot={false}
            strokeWidth={1.5}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};



