import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ReactSVG } from 'react-svg'
import Popup from 'reactjs-popup';
import { UComponent, BnSubpage } from '../Page'
import Image from '../../assets/Icons/Image.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import CheckMark from '../../assets/Icons/Tick.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import Spin from '../../assets/Icons/Spin.svg'
import Down from '../../assets/Icons/Down.svg'
import Up from '../../assets/Icons/Up.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import { KeyboardButton, KeyboardButton1 } from '../Keyboard'
import Alert from '../../assets/Icons/Alert.svg'
import { GearButton, Checkbox, SimpleButton, SimpleIcon } from './index.js'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Scrollbar, Mousewheel } from 'swiper/modules';
import { isMobile, isDesktop } from '../../classes/Platform.js'
const moment = require('moment')
import './ModelsMenu.css'
import './ScrollList.css'

class VendorItems extends Component {

  constructor(props) {
    super(props)
    this.state = {mounted: false}
  }

  componentDidMount() {
    this.setState({
      mounted: true
    })
  }

  componentWillUnmount() {
  }
  
  render() {
    let style
    if (this.state.mounted) {
      style = {
        opacity: 1
      }
    }
    return <div className='desktopVendorItemsDiv' style={style}>
      {this.props.children}
    </div>
  }
}

export class PopupSelectionList extends Component {
  render() {
    return <div className='popupSelectionList'>
             <div className='popupSelectionListTitle'>
               {this.props.title}
             </div>
             <div className='popupSelectionListSelection'>
               <select>
                 {
                   this.props.options.map(option => {
                     return <option key={option.key} value={option.value}>
                              {option.label}
                            </option>
                   })
                 }
               </select>
             </div>
           </div>
  }
}

export class ScrollList extends Component {
  constructor (props) {
    super(props)
  }
  onSlideChange = e => {
  }
  onSwiper = ref => {
    this.swiper = ref
  }
  render() {
    const left = () => {
      this.swiper.slideTo(this.swiper.activeIndex - 1)
    }
    const right = () => {
      this.swiper.slideTo(this.swiper.activeIndex +1)
    }
    return <div className='scrollList'>
             <div className='titleRow'>
               <div className='scrollListTitle'>{this.props.title}</div>
               <div className='scrollListChoice'>{this.props.choice}</div>
             </div>
             <div className='contentRow'>
               <SimpleButton icon={Left} action={left}/>
               <Swiper
                 preventClicks={false}
                 onSwiper={this.onSwiper}
                 modules={[Mousewheel]}
                 allowTouchMove={!isDesktop()}
                 onSlideChange={this.onSlideChange}
                 slidesPerView={'auto'}
                 mousewheel={isDesktop() ? { forceToAxis: true, releaseOnEdges: true  } : undefined}
               >
                 {this.props.children.map(x => <SwiperSlide>{x}</SwiperSlide>)}
               </Swiper>
               <SimpleButton icon={Right} action={right}/>
             </div>
           </div>
  }
}


export const formatPrice = (price, N = 2) => {
  if (price <= 0) {
    return '0.00'
  }
  if (price >=  0.10) {
    return price.toFixed(2)
  }
  if (price <= 0.00001) {
    return '0.00001'
  }
  // Convert the price to a string and split at the decimal point
  let priceStr = price.toString();
  let [wholePart, decimalPart] = priceStr.split('.');
  
  if (!decimalPart) {
    return wholePart 
  }

  // Count leading zeros in the decimal part
  let leadingZeros = decimalPart.match(/^0*/)[0].length;
  
  // Calculate the total number of decimal places to keep
  let totalDecimals = leadingZeros + N;
  
  // Format the price with the calculated number of decimals
  let formattedPrice = price.toFixed(totalDecimals);

  // Remove trailing zeros
  formattedPrice = parseFloat(formattedPrice).toString();
  return formattedPrice;
}

export const ModelPrice = props => {
  const { price } = props
  return <div className='modelPriceStyle'>
           {price}
         </div>
}

export const ModelIcon = props => {
  const { model } = props
  const { getModelIcon, getIcon } = model
  let icon = (getModelIcon && getModelIcon()) || getIcon()
  return <SimpleIcon src={icon}/>
}

export const ModelLabel = props => {
  const { model } = props
  let version
  const { title, isFinetune, modelId, rootModel } = model
  if (isFinetune) {
    function formatDate(date) {
      const momentDate = moment(date)
      const currentYear = moment().year();
      
      // Check if the year of the date matches the current year
      if (momentDate.year() === currentYear) {
        return momentDate.format('MM/DD');
      } else {
        return momentDate.format('MM/DD/YY');
      }
    }
    const date = formatDate(model.ts)
    version = <div className='modelVersion'><div className='modelVersionDate'>{date}</div><div className='modelVersionId'>{modelId}</div></div>
  }
  const label = title
  return <div className='modelLabel'>
           <div className='modelName'>{label}</div>
           {version} 
         </div>
  
}

export class Model extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }
  render() {
    let { model, single, active, action, button, preview } = this.props
    let { id, title, label, getIcon, vendor, getModelIcon, vision, legacy, isFinetune, modelId } = model
    
    const select = model.select || action
    const isSelected = () => model.isSelected && model.isSelected()
    label = title
    let busy = this.state.busy || this.props.busy
    let iconStyle
    let icon = getModelIcon ? getModelIcon() : null
    if (single) {
      icon = icon || model.getIcon()
    } else if (!preview) {
      iconStyle = getModelIcon && getModelIcon() ? null : { visibility: 'hidden', maxWidth: 15 }
    } else {
      icon = icon || model.getIcon()
    }
    if (busy) {
      icon = Spin
    }
    let className= 'model'
    if (isSelected()) className += '  modelRadioButtonSelected'
    if (!single) {
      className += ' modelSubmenu'
    }
    if (preview) {
      className += ' modelPreview'
    }
    const format = this.props.formatPrice || formatPrice
    let price = this.props.price || ''
    if (this.props.getPrice) {
      const { input, output } = this.props.getPrice(model)
      if (input) {
        if (input === output) {
          price = "$" + format(input)
        } else {
          price = "$" + format(input) + "/" + format(output)
        }
      }
    }
    if (legacy) {
      className += ' modelLegacy'
    }
    let configure
    let configIcon = this.state.showConfiguration ? Up : Down
    if (this.props.configure) {
      configure = () => {
        this.setState({
          showConfiguration: !this.state.showConfiguration
        })
      }
    }
    let config
    if (this.state.showConfiguration) {
      config = this.props.configure(model)
    }
    //console.log('Model config', config, 'configure', this.props.configure)
    if (model.providerId === 'custom') {
      single = true
    }
    let vendorLabel
    if (single){
      if (vendor.length + model.title.length <= 25) {
        vendorLabel = <div className='modelLabel modelVendorLabel'>{vendor}</div>
      }
    }
    return <div className='configurableModel'>
             <div className={className} >
               <div className='modelSelector' onClick={select}>
                 <div className='modelLeft' >
                   <div className='modelIcon' style={iconStyle}>
                     <ReactSVG src={icon}/>
                   </div>
                   <div className='modelVendorAndLabel'>
                     {vendorLabel}
                     <ModelLabel model={model}/>
                   </div>
                 </div>
                 <div className='modelPrice'>
                   {price}
                 </div>
                 <div className='modelVision'>
                   {!button && vision && <ReactSVG src={Image}/>}
                 </div>
               </div>
               <div className='modelRight'>
                 {configure && <SimpleButton icon={configIcon} action={configure}/>}
               </div>
             </div>
             {config}
           </div>
  }
}


export class ModelVendor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: this.props.open
    }
  }

  renderModels(preview) {
    const getPrice = m => (m.contexts && m.contexts[0].price) || { input: 0, output: 0 }
    let models = [].concat(this.props.models)
    if (this.props.preview) {
      models = models.filter(x => {
        return this.props.getPrice(x).input
      })
    }
    const byId = {}
    models.forEach(m => {
      byId[m.id] = m
    })
    const isDerivedFrom = (x, y) => {
      if (x.isFinetune) {
        return x.rootModel == y.id
      }
    }
    models.sort((x, y) => {
      if (isDerivedFrom(x, y)) {
        return x
      }
      if (isDerivedFrom(y, x)) {
        return y
      }
      const price1 = getPrice(x)
      const price2 = getPrice(y)
      let cmp = price2.input - price1.input
      if (cmp) {
        return cmp
      }
      if (y.isFinetune && !x.isFinetune) {
        return x
      }
      else if (x.isFinetune && !y.isFinetune) {
        return y
      } 
      cmp = y.ts - x.ts
      if (cmp) {
        return cmp
      }
      return x.title.localeCompare(y.title)
    })
    ////console.log({sortedModels: models})
    return models.map(model => {
      return <Model
               preview={preview}
               key={model.id}
               me={this.props.me}
               model={model}
               configure={this.props.configure}
               formatPrice={this.props.formatPrice}
               getPrice={this.props.getPrice}/>
    })
  }
  toggleMenu = () => {
    this.setState({
      open: !this.state.open
    })
  }
  render() {
    const open = this.state.open || this.props.open
    const { models } = this.props
    if (this.props.preview) {
      return this.renderModels(true)
             
    }
    //////console.log({models})
    const format = this.props.formatPrice || formatPrice
    let selectionCount = models.reduce((a, x) => a + (x.isSelected() ? 1 : 0), 0)
    let isSelected = selectionCount > 0
    if (!this.props.preview && models.length === 1) {
      return <Model configure={this.props.configure} key={models[0].id} me={this.props.me} model={models[0]} single={true} getPrice={this.props.getPrice} formatPrice={this.props.formatPrice}/>
    }
    let style
    if (models.length === 0) {
      style = {display: 'none'}
    }
    let price
    let openStyle = this.props.open ? { visibility: 'hidden' } : null
    ////console.log({models})
    if (this.props.aggregatePrice) {
      const { input, output } = this.props.aggregatePrice
      price = "$" + format(input) + "/" + format(output)
    }
    return <div className={'modelCategory' + (open ? ' modelCategoryOpen' : '')} style={style}>
             <div className={'modelCategoryMenu' + (isSelected ? ' modelRadioButtonSelected' : '')} onClick={this.toggleMenu}>
               <div className='modelCategoryLeft'>
                 <div className='modelIcon'>
                   <ReactSVG src={this.props.vendor.getIcon()}/>
                 </div>
                 <div className='modelLabel modelVendorLabel'>
                   {this.props.vendor.name}
                 </div>
               </div>
               <div className='modelCategoryRight'>
                 {price && <div className='modelPrice'>{price}</div>}
                 {selectionCount > 0 && <div className='modelVendorSelectionCountContainer'><div className='modelVendorSelectionCount'>{selectionCount}</div><div className='modelVendorCheck'><ReactSVG src={CheckMark}/></div></div>}
                 <div className='modelIconOpen' style={openStyle}>
                   <SimpleIcon src={open ? Up : Down}/>
                 </div>
               </div>
             </div>
             {open && <div className='modelCategoryButtons'>
                         {this.renderModels(this.props.preview)}
                       </div>}
           </div>
  }
}


export class ModelsMenu extends UComponent {
  constructor(props) {
    super(props)
    this.state = {
      menuActive: true
    }
  }


  setScrollRef = ref => {
    this.scrollRef = ref
  }
    
  renderMenu = (close, contentStyle) => {
    let style = contentStyle
    let ref = document.getElementById('modelMenuTrigger')
    let selectedModel
    const selectModel = (model) => {
      model.select()
      close()
    }
    const closeMenu = () => this.setState({menuActive: false})
    const isLarge = () => {
      return this.props.sizes['large']
    }
    const isMedium = () => {
      return this.props.sizes['medium']
    }
    const isSmall = () => {
      return this.props.sizes['small']
    }
    const selectedModels = this.props.modelsForSelection.filter(model => {
      return this.props.selectedModels[model.id]
    })
    
    const vendors = {}
    let vendorList = this.props.vendors
    for (const model of this.props.modelsForSelection) {
      let arr = vendors[model.providerId || model.vendorId]
      if (!arr) {
        vendors[model.providerId || model.vendorId] = arr = []
      }
      if (model.providerId) {
        //debugger
      }
      arr.push(model)
    }
    let vendorItems = []
    const selectedVendors = this.props.keys(vendors)
    for (const vendor of vendorList) {
      const models = vendors[vendor.id] || []
      if (vendor.id ===  'hf') {
        //debugger
      }
      vendorItems.push(<ModelVendor
                         preview={this.props.preview}
                         key={vendor.id}
                         configure={this.props.configure}
                         open={selectedVendors.length === 1}
                         vendor={vendor}
                         me={this.props.me}
                         models={models}
                         formatPrice={this.props.formatPrice}
                         getPrice={this.props.getPrice}/>)
    }
    const toggleLarge = () => {
      this.props.toggleLarge()
    }
    const toggleMedium = () => {
      this.props.toggleMedium()
    }
    const toggleSmall = () => {
      this.props.toggleSmall()
    }
    const isVision = () => {
      return this.props.isVision
    }
    const toggleVision = () => {
      this.props.toggleVision()
    }
    const isImage = () => {
      return this.props.isImage
    }
    const toggleImage = () => {
      this.props.toggleImage()
    }
    const isClosed = () => {
      return this.props.isClosed
    }
    const toggleClosed = () => {
      this.props.toggleClosed()
    }
    const isBase = () => {
      return this.props.isBase
    }
    const toggleBase = () => {
      this.props.toggleBase()
    }
    const isLegacy = () => {
      return this.props.isLegacy
    }
    const toggleLegacy = () => {
      this.props.toggleLegacy()
    }
    const getCheckpoints = () => {
      return this.props.checkpoints
    }
    const toggleCheckpoints = () => {
      return this.props.toggleCheckpoints()
    }
    const isFinetune = (model) => {
      return this.props.isFinetune(model)
    }
    const toggleFinetune = (model) => {
      this.props.toggleFinetune(model)
    }
    const isFinetunable = () => {
      return this.props.isFinetunable
    }
    const toggleFinetunable = () => {
      this.props.toggleFinetunable()
    }
    
    const isInstruct = () => {
      return this.props.isInstruct
    }
    const toggleInstruct = () => {
      this.props.toggleInstruct()
    }
    const supportsContext = context => {
      return context === this.props.inputContext
    }
    const toggleContext = context => {
      this.props.setInputContext(context)
    }
    const supportsOutputContext = context => {
      return context ===this.props.outputContext
    }
    const toggleOutputContext = context => {
      this.props.setOutputContext(context)
    }
    const supportsPrice = context => {
      return context === this.props.inputPrice
    }
    const togglePrice = context => {
      this.props.setInputPrice(context)
    }
    let className = 'chatGptModelsMenu'
    if (isDesktop()) {
      className += ' chatGptModelsMenuDesktop'
    }
    if (this.props.className) {
      className += ' ' + this.props.className
    }
      
    //////console.log('modelsMenu', {selectedModels, propsSelectedModels: this.props.selectedModels})
    const Finetunable = {}
    let finetunables = []
    for (const model of this.props.modelsForSelection) {
      if (model.finetune && !model.isFinetune) {
        if (!Finetunable[model.id]) {
          Finetunable[model.id] = model
          finetunables.push(model)
        }
      }
    }
    const finetunable = finetunables.length > 0
    let done
    let clear
    if (!this.props.inline) {
      done = () => this.hidePopup()
      clear = () => {
        this.props.clearOptions()
      }
    }
    if (this.props.preview) {
      className += ' modelsMenuPreview'
    }
    if (this.props.unselectable) {
      className += ' modelsMenuNoSelect'
    }
    if (this.props.vendorOnly) {
      className += ' modelsMenuVendorOnly'
    }
    if (this.props.inline && !this.props.preview && !isMobile() && this.props.openDetail) {
      const vendorItemsDiv =  () => <VendorItems>{vendorItems}</VendorItems>
      vendorItems = this.props.openDetail(vendorItemsDiv, 'models')
    } else {
      vendorItems = <div className='vendorItemsInline'>{vendorItems}</div>
    }
    return <div className={className} style={style}>
             <div className='chatGptThreadsMenuOptionsContainer modelOptionsTitle'>
               
               {(this.props.selectable && !this.props.preview && selectedModels.length === 0 && <div key='title' className='chatGPTModelsMenuTitle'>
                                                  <SimpleIcon src={Alert}/>Please select one or more models
                                                                       </div>) ||
                <div className='chatGPTModelsMenuTitleNormal'>
                  <div className='doneButton'>
                    <SimpleButton label={'Clear'} action={clear}/>
                  </div>                  
                  <div className='modelsMenuModelsText'>
                    <SimpleIcon src={Hashtag}/>
                    {this.props.placeholder || 'Models'}
                  </div>
                  <div className='doneButton'>
                    <SimpleButton label={'Done'} action={done}/>
                  </div>
                </div>}
             </div>
             <div className='chatGptThreadsMenuOptionsContainer'>
               <div key='vendors' className='chatGptThreadsVendorOptions'>
                 {
                   this.props.vendors.map(vendor => {
                     //////console.log("VENDOR", vendor)
                     const toggle = async () => {
                       this.props.toggleVendor(vendor)
                     }
                     const selected = this.props.isVendorSelected(vendor)
                     let className = 'modelsMenuVendor'
                     if (selected) {
                       className += ' modelsMenuVendorSelected'
                     }
                     return <div key={vendor.id} className={className}>
                              <SimpleButton icon={vendor.getIcon()} action={toggle}/>
                            </div>
                   })
                 }
               </div>
             </div>
             <div className='chatGptThreadsMenuOptionsContainer modelOptionsContainer'>
               <div className='chatGptThreadsMenuOptions modelsMenuOptionsSize'>
                 <div className='modelsMenuGroup'>
                   <Checkbox selected={isSmall()} toggle={toggleSmall} label='Small'/>
                   <Checkbox selected={isMedium()} toggle={toggleMedium} label={'Medium'}/>
                   <Checkbox selected={isLarge()} toggle={toggleLarge} label='Large'/>
                   <Checkbox legacyIconSize icon={Image} selected={isVision()} toggle={toggleVision} label='Vision'/>
                 </div>
                 <div className='modelsMenuGroup'>
                   <Checkbox selected={isBase()} toggle={toggleBase} label='Base'/>
                   <Checkbox className='finetunableModel' selected={isFinetunable()} toggle={toggleFinetunable} label='Fine-tunable'/>
                   <Checkbox className='legacy' selected={isLegacy()} toggle={toggleLegacy} label='Legacy'/>
                   { finetunables.find(model => model.vendorId === 'openai' && isFinetune(model.id)) &&
                     <Checkbox label={"Checkpoints"} toggle={toggleCheckpoints} selected={getCheckpoints()}/>}
                                                    
                 </div>
               </div>
               <div className='chatGptThreadsMenuOptions modelsMenuOptionsInputContext'>
                 <div className='optionLabel'>Input</div>
                 <Checkbox selected={supportsContext(4)} toggle={() => toggleContext(4)} label={'4K'}/>
                 <Checkbox selected={supportsContext(8)} toggle={() => toggleContext(8)} label={'8K'}/>
                 <Checkbox selected={supportsContext(16)} toggle={() => toggleContext(16)} label='16K'/>
                 <Checkbox selected={supportsContext(128)} toggle={() => toggleContext(128)} label='128K'/>
                 <Checkbox selected={supportsContext(256)} toggle={() => toggleContext(256)} label='200K'/>
                 <Checkbox selected={supportsContext(1000)} toggle={() => toggleContext(1000)} label='1M'/>
                 <Checkbox selected={supportsContext(2000)} toggle={() => toggleContext(2000)} label='2M'/>
               </div>
               <div className='chatGptThreadsMenuOptions modelsMenuOptionsOutputContext'>
                 <div className='optionLabel'>Output</div>
                 <Checkbox selected={supportsOutputContext(4)} toggle={() => toggleOutputContext(4)} label={'4K'}/>
                 <Checkbox selected={supportsOutputContext(8)} toggle={() => toggleOutputContext(8)} label={'8K'}/>
                 <Checkbox selected={supportsOutputContext(16)} toggle={() => toggleOutputContext(16)} label='16K'/>
               </div>
               <div className='chatGptThreadsMenuOptions modelsMenuOptionsPrice'>
                 <ScrollList title={'Max Price'} choice={<div className='inputPrice'>${this.props.inputPrice.toFixed(2)}</div>}>
                   <Checkbox selected={supportsPrice(.10)} toggle={() => togglePrice(.10)} label={'.10'}/>
                   <Checkbox selected={supportsPrice(.15)} toggle={() => togglePrice(.15)} label={'.15'}/>
                   <Checkbox selected={supportsPrice(.30)} toggle={() => togglePrice(.30)} label={'.30'}/>
                   <Checkbox selected={supportsPrice(1)} toggle={() => togglePrice(1)} label='$1'/>
                   <Checkbox selected={supportsPrice(2)} toggle={() => togglePrice(2)} label='$2'/>
                   <Checkbox selected={supportsPrice(3)} toggle={() => togglePrice(3)} label='$3'/>
                   <Checkbox selected={supportsPrice(4)} toggle={() => togglePrice(4)} label='$4'/>
                   <Checkbox selected={supportsPrice(5)} toggle={() => togglePrice(5)} label='$5'/>
                   <Checkbox selected={supportsPrice(10)} toggle={() => togglePrice(10)} label='$10'/>
                   <Checkbox selected={supportsPrice(20)} toggle={() => togglePrice(20)} label='$20'/>
                   <Checkbox selected={supportsPrice(30)} toggle={() => togglePrice(30)} label='$30'/>
                 </ScrollList>
               </div>
               {finetunable &&<div className='chatGptThreadsMenuOptions modelsMenuOptionsFineTuning'>
                                <ScrollList title={"Fine-tuned"}>
                                  {
                                    finetunables.map(model => {
                                      let icon = (model.getModelIcon && model.getModelIcon()) || model.getIcon()
                                      return <Checkbox icon={icon} selected={isFinetune(model.id)} toggle={() => toggleFinetune(model.id)} label={model.name}/>
                                    })
                                  }
                                </ScrollList>
                              </div>
               }
             </div>
             {vendorItems}
             <div className='modelsMenuButtons'>
               {this.props.children}
             </div>
      </div>
  }

  renderFineTuned = () => {
  }


  setPopupRef = ref => {
    this.popupRef = ref
  }

  openMenu = () => {
  }

  openIfNecessary = () => {
  }

  showPopup = () => {
    this.popupRef.open()
  }

  hidePopup = () => {
    this.popupRef.close()
  }

  togglePopup = () => {
    ////console.log("togglePopup", this.open)
    if (this.open) {
      this.hidePopup()
    } else {
      this.showPopup()
    }
  }

  componentDidMount() {
    this.state.closeOnClick = !this.props.menuActive
    this.props.onCreate(this)
  }

  componentDidUpdate() {
    if (this.popupRef && this.props.menuActive) {
      setTimeout(this.popupRef.open, 500)
    } else {
    }
  }

  setRef = ref => {
    this.ref = ref
  }

  render() {
    if (this.props.inline) {
      let className = 'modelsMenuInline'
      if (this.props.preview) {
        className += ' modelsMenuInlinePreview'
      }
      return <div className={className}>
               {this.renderMenu()}
             </div>
    }
    let position = 'bottom left'
    let contentStyle
    ////console.log("modelsmenu.ref", this.ref)
    if (this.ref) {
      const rect = this.ref.getBoundingClientRect()
      if (rect.top > window.innerHeight * .55) {
        position = "top left" 
        const h = rect.top - 40
        contentStyle = {
          maxHeight: h,
          overflowY: 'auto',
        }
      } else {
        position = 'bottom left'
        const h = window.innerHeight - Math.max(rect.top + 40, rect.bottom) 
        contentStyle = {
          maxHeight: h,
          overflowY: 'auto',
        }
      }
      ////console.log("buttonRef", rect, contentStyle)
    }
    let icon
    if (this.props.getIcon) {
      icon = this.props.getIcon(this.state.menuActive)
    } else {
      icon = this.props.menuActive || this.state.menuActive ? MenuDown : MenuUp
    }
    const trigger = <div id={'modelMenuTrigger'} className='keyboardAddButton'>
                      <KeyboardButton1 icon={icon} action={async () => {}  }/>
                    </div>
    let t
    return <div className='modelMenuPopupHolder' ref={this.setRef}>
             <Popup ref={this.setPopupRef} closeOnDocumentClick trigger={trigger} position={position} onOpen={() => {
                      this.open = true
                    }}
                    onClose={() => {
                      clearTimeout(t)
                      t= setTimeout(() => {
                        this.open = false
                        ////console.log("on close popup")
                      }, 40)
                    }}>
               {close => this.renderMenu(close, contentStyle)}
             </Popup>
           </div>
  }
}


export class ModelsView extends BnSubpage {

  constructor (props) {
    super(props)
    this.state = {
      options: {
      }
    }
    if (!this.props.category) {
      throw new Error("category is required")
    }
    this.state.options[this.props.category] = {
      ts: 0,
      temperature: 1.0,
      top_p: 1.0,
      baseModels: false,
      visionModels: false,
      legacyModels: false,
      selectedModels: {},
      selectedVendors: {},
      selectedFinetunes: {},
      sizes: { },
      inputContext: 4,
      outputContext: 4,
      inputPrice: 30
    }
    //////console.log('state options', this.state.options)
  }
  
  saveOptionsLater = () =>  {
    if (!this.props.saveOptions) {
      return
    }
    //this.props.onOptionsChanged()
    this.state.options.ts = Date.now()    
    this.updateLater('saveOptions', () => this.props.saveOptions(this.state.options))
  }

  clearOptions = () => {
    this.state.options[this.props.category] = {
      ts: Date.now(),
      temperature: 1.0,
      top_p: 1.0,
      baseModels: false,
      visionModels: false,
      legacyModels: false,
      selectedModels: {},
      selectedVendors: {},
      selectedFinetunes: {},
      sizes: { },
      inputContext: 4,
      outputContext: 4,
      inputPrice: 20
    }
    this.invalidate()
    this.forceUpdate(this.onOptionsChanged)
    this.saveOptionsLater()
  }

  getOptions = () => {
    if (!this.state.options[this.props.category]) {
      this.state.options[this.props.category] = {
        ts: 0,
        temperature: 1.0,
        top_p: 1.0,
        baseModels: false,
        visionModels: false,
        legacyModels: false,
        selectedModels: {},
        selectedVendors: {},
        selectedFinetunes: {},
        sizes: { },
        inputContext: 4,
        outputContext: 4,
        inputPrice: 20
      }
    }
    const result = this.state.options[this.props.category]
    //////console.log("get options", this.props.category, this.state.options, result)
    return result
  }
  
  saveOptions = async () => {
    this.state.options.ts = Date.now()
    await this.props.saveOptions(this.state.options)
  }

  componentDidMount() {
    this.observeOptions()
    if (this.props.onCreate) this.props.onCreate(this)
  }

  componentWillUnmount() {
    if (this.optionsSub) {
      this.optionsSub.unsubscribe()
    }
  }

  observeOptions = () => {
    this.optionsSub = this.props.observeOptions().subscribe(data => {
      let { options } = data
      //////console.log("models menu observe options", this.props.category, options)
      //debugger
      this.updateOptionsLater(options)      
    })
  }

  updateOptionsLater = options => {
    this.updateLater('updateOptions', () => this.updateOptions(options))
  }

  invalidate = () => {
    this.selectedModelsList = null
    this.selectedModelIds = null
    this.selectedModelsListFiltered = null
  }

  updateOptions = options => {
    if (options && this.state.options) {
      if (options.ts !== this.state.options.ts) {
        //console.log("options ts changed")
        this.invalidate()
        this.setState({
          options
        },this.props.onOptionsChanged)
      }
    }
  }

  hasVendorSelected = () => {
    for (const id in this.state.selectedVendors) {
      if (this.state.selectedVendors[id]) {
        return true
      }
    }
  }
  
  selectModel = (model) => {
    if (this.props.singleSelect) {
      for (const id in this.state.selectedModels) {
        this.state.selectedModels[id] = false
      }
    }
    const isSelected = this.isModelSelected(model)
    if (!isSelected) {
      const m = this.modelsById[model]
      //debugger
      if (this.hasVendorSelected() && !this.isVendorSelected({id: m.vendorId})) {
        this.toggleOption2('selectedVendors', m.vendorId)
      }
      if (m.finetune && !this.isFinetune(m.rootModel)) {
        this.toggleFinetune(m.rootModel)
      }
    }
    this.toggleOption2('selectedModels', model)
  }

  isModelSelected = (model) => {
    const options = this.getOptions()
    return options.selectedModels[model]
  }

  getModelPrice = model => {
    if (model.providerId) {
      return { input: 0, output: 0 }
    }
    if (this.props.getPrice) {
      return this.props.getPrice(model)
    }
    const options = this.getOptions()
    const { inputContext, outputContext } = options
    if (!model.contexts) {
      return inputContext  === 4 && outputContext === 4 ? model.price: null
    }
    for (let i = 0; i < model.contexts.length; i++) {
      if (model.contexts[i].input >= inputContext &&
          (model.image || model.contexts[i].output >= outputContext)) {
        return model.contexts[i].price
      }
    }
  }

  getSelectedModelIds = () => {
    if (this.selectedModelIds) {
      return this.selectedModelIds
    }
    const options = this.getOptions()
    const selected = this.getSelectedModelsList().filter(x => options.selectedModels[x.id])
    return this.selectedModelIds = selected.map(x => x.id)
  }

  getModelsForSelection = () => {
    const selected = this.getSelectedModelsList()
    const result = {}
    for (const model of selected) {
      result[model.id] = true
    }
    return result
  }

  getSelectedModels = () => {
    const ids = this.getSelectedModelIds()
    const result = {}
    for (const id of ids) {
      result[id] = true
    }
    return result
  }

  getSelectedModelsListFiltered = () => {
    if (this.selectedModelsListFiltered) {
      return this.selectedModelsListFiltered
    }
    const options = this.getOptions()
    return this.selectedModelsListFiltered = this.getSelectedModelsList().filter(x => x.id === this.props.fineTunedModel || options.selectedModels[x.id])
  }

  keys = obj => {
    const keys = Object.keys(obj)
    return keys.filter(key => obj[key] !== null)
  }

  values = obj => {
    return Object.values(obj).filter(x => x !== null)
  }

  supportsCheckpoints = model => {
    return model.finetune && model.finetune.inference && model.finetune.inference.checkpoints
  }
  
  getSelectedModelsList = () => {
    if (!this.selecteddModelsList) {
      this.updateSelectedModelsList()
    }
    return this.selectedModelsList
  }

  updateSelectedModelsList = () => {
    const options = this.getOptions()
    //////console.log("options", options)
    if (!options) return []
    const models = this.getModels()
    ////console.log("options", {options})
    const allVendors =  Object.keys(options.selectedVendors).filter(x => {
      return options.selectedVendors[x] && this.props.vendors.find(y => y.id === x)
    }).length === 0
    const allSizes =  this.values(options.sizes).length === 0
    const allFinetunes = this.values(options.selectedFinetunes || {}).length === 0
    const selected = models.filter(model => {
      if (model.id === this.props.fineTunedModel) {
        return true
      }
      if (model.deleted) {
        return false
      }
      if (!this.props.vendors) {
        return false
      }
      if (model.providerId) {
        //debugger
      }
      const vendor = this.props.vendors.find(x => x.id === model.providerId || x.id === model.vendorId)
      if (vendor) {
        let sizeOk = true
        if (!allSizes) {
          sizeOk = options.sizes[model.getSize()]
        }
        const vendorOk = allVendors || options.selectedVendors[vendor.id]
        ////console.log({model, sizeOk, vendorOk})
        let checkpointsOk = this.supportsCheckpoints(model) &&  this.getCheckpoints()
        let visionOk = true
        let imageOk = true
        let baseOk = true
        let legacyOk = false
        let finetuneOk = true
        let finetunesOk = true
        let priceOk = false
        let contextOk = this.getModelPrice(model)
        let checkpointOk = true
        if (contextOk) {
          const { input } = contextOk
          priceOk = input <= options.inputPrice
        }
        if (options.finetune) {
          finetuneOk = model.finetune && !model.isCheckpoint
        }
        if (!checkpointsOk) {
            checkpointOk = !model.isFinetune || !model.isCheckpoint
        }
        if (options.visionModels) {
          visionOk = model.vision
        }
        if (options.imageGeneration) {
          imageOk = model.image
        }
        if (model.isBase) {
          baseOk = options.baseModels
        }
        if (options.legacyModels) {
          legacyOk = true
        } else {
          legacyOk = !model.legacy
        }
        if (!allFinetunes) {
          finetunesOk = !model.isFinetune || options.selectedFinetunes[model.rootModel]
        } else {
          finetunesOk = !model.isFinetune
        }
        if (model.providerId) {
          contextOk = true
          priceOk = true
        }
        ////console.log({model,
        //contextOk,  sizeOk,  vendorOk,  visionOk,  baseOk,  legacyOk,  priceOk,  finetunesOk,  finetuneOk,  checkpointOk,  imageOk
        //})
        const result = contextOk && sizeOk && vendorOk && visionOk && baseOk && legacyOk && priceOk && finetunesOk && finetuneOk && checkpointOk && imageOk
        ////console.log(model.name, result)
        return result
      } else {
        //debugger
      }
      return false
    })
    this.selectedModelsList = selected
  }

  isVision = () => {
    return this.getOption('visionModels')
  }

  isImage = () => {
    return this.getOption('imageGeneration')
  }

  toggleImage = () => {
    this.toggleOption('imageGeneration')
  }

  toggleOption = option => {
    const options = this.getOptions()
    options[option] = !options[option]
    this.forceUpdate1(this.saveOptionsLater)
  }

  getOption = option => {
    const options = this.getOptions()
    return options[option]
  }

  setOption = (updates) => {
    const options = this.getOptions()
    for (const key in updates) {
      options[key] = updates[key]
    }
    this.forceUpdate1(this.saveOptionsLater)
  }

  toggleOption2 = (option, key) => {
    let options = this.getOptions()
    if (!options[option]) {
      options[option] = {}
    }
    if (options[option][key]) {
      options[option][key] = null
    } else {
      options[option][key] = true
    }
    this.forceUpdate1(this.saveOptionsLater)
  }

  forceUpdate1 = (k) => {
    //console.log("forceUpdate", this.menu && this.menu.open)
    this.invalidate()
    this.props.onOptionsChanged()
    if (this.menu && this.menu.open) {
      this.forceUpdate(k)
    } else {
      if (k) k()
    }
  }

  getOption2 = (option, key) => {
    let options = this.getOptions()[option]
    return (options && options[key] !== null && options[key]) || undefined
  }

  toggleVision = () => {
    this.toggleOption('visionModels')
  }

  toggleOpenSource = () => {
    this.toggleOption('openSource')
  }

  isLegacy = () => {
    return this.getOption('legacyModels')
  }

  toggleLegacy = () => {
    this.toggleOption('legacyModels')
  }

  isFinetunable = () => {
    return this.getOption('finetune')
  }

  toggleFinetunable = () => {
    this.toggleOption('finetune')
  }

  toggleCheckpoints = () => {
    this.toggleOption('checkpoints')
  }

  getCheckpoints = () => {
    return this.getOption('checkpoints')
  }

  isFinetune = (model) => {
    return this.getOption2('selectedFinetunes', model)
  }

  toggleFinetune = (model) => {
    this.toggleOption2('selectedFinetunes', model)
  }


  isBase = () => {
    return this.getOption('baseModels')
  }

  toggleBase = () => {
    return this.toggleOption('baseModels')
  }

  isVendorSelected = vendor => {
    return this.getOption2('selectedVendors', vendor.id)
  }

  toggleVendor = vendor => {
    if (this.props.preview) {
      //return
    }
    this.toggleOption2('selectedVendors', vendor.id)
  }

  setOutputContext = outputContext => {
    this.setOption({outputContext})
  }
  
  setInputContext = inputContext => {
    this.setOption({inputContext})
  }

  setInputPrice = inputPrice => {
    this.setOption({inputPrice})
  }
  
  getInputPrice= () =>  {
    return this.getOption('inputPrice')
  }

  getInputContext = () => {
    return this.getOption('inputContext')
  }

  getOutputContext = () => {
    return this.getOption('outputContext')
  }

  getModels = () => {
    if (!this.models) {
      this.models = this.props.models(this.isModelSelected, this.selectModel).filter(x => {
        return x.getSize
      })
      this.modelsById = {}
      this.models.forEach(model => {
        this.modelsById[model.id] = model
      })
    }
    return this.models
  }

  onCreateMenu = menu => {
    this.menu = menu
  }

  showPopup = () => {
    this.menu.showPopup()
  }

  hidePopup = () => {
    this.menu.hidePopup()
  }

  togglePopup = () => {
    this.menu.togglePopup()
  }

  render() {
    if (this.props.inline) {
      return this.renderContent()
    }
    return super.render()
  }

  renderContent() {
    const toggleSize = (size) => {
      this.toggleOption2('sizes', size)
    }
    const getSizes = () => this.getOption('sizes')
    const toggleSmall = () => toggleSize('small')
    const toggleMedium = () => toggleSize('medium')
    const toggleLarge = () => toggleSize('large')
    let modelsMenu = (menuActive) => <ModelsMenu
                                       openDetail={this.props.openDetail}
                                       clearOptions={this.clearOptions}
                                       configure={this.props.configure}
                                       placeholder={this.props.placeholder}
                                       getIcon={this.props.getIcon}
                                       className={this.props.className}
                                       onCreate={this.onCreateMenu}
                                       keys={this.keys}
                                       collection={this.props.collection || 'models'}
                                       inline={menuActive}
                                       formatPrice={price => formatPrice(price, 5)}
                                       menuActive={menuActive}
                                       getPrice={this.getModelPrice}
                                       isVendorSelected={this.isVendorSelected}
                                       toggleVendor={this.toggleVendor}
                                       vendors={this.props.vendors.filter(x => !x.disabled)}
                                       models={this.getModels()}
                                       selectedModels={this.getSelectedModels()}
                                       modelsForSelection={this.getSelectedModelsList()}
                                       visible={true}
                                       sizes={getSizes()}
                                       toggleSmall={toggleSmall}                 
                                       toggleMedium={toggleMedium}                 
                                       toggleLarge={toggleLarge}
                                       toggleVision={this.toggleVision}
                                       isImage={this.isImage()}
                                       toggleImage={this.toggleImage}
                                       isVision={this.isVision()}
                                       toggleBase={this.toggleBase}
                                       toggleLegacy={this.toggleLegacy}
                                       inputContext={this.getInputContext()}
                                       outputContext={this.getOutputContext()}
                                       setInputContext={this.setInputContext}
                                       setOutputContext={this.setOutputContext}
                                       inputPrice={this.getInputPrice()}
                                       setInputPrice={this.setInputPrice}
                                       isBase={this.isBase()}
                                       isLegacy={this.isLegacy()}
                                       toggleFinetune={this.toggleFinetune}
                                       isFinetune={this.isFinetune}
                                       toggleCheckpoints={this.toggleCheckpoints}
                                       checkpoints={this.getCheckpoints()}
                                       toggleFinetunable={this.toggleFinetunable}
                                       isFinetunable={this.isFinetunable()}
                                       preview={this.props.preview}
                                       vendorOnly={this.props.vendorOnly}
                                       unselectable={this.props.unselectable}
                                     >
                                       {this.props.children}
                                     </ModelsMenu>
    return modelsMenu(this.props.menuActive)
  }
}
