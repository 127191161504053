
export const streamReply = (url, data, token, opts) => {
  //console.log('streamReply', opts)
  const { onContent, onDone, onError, resolve, reject } = opts
  const xhr = new XMLHttpRequest();
  //console.log('POST', url, data, opts)
  xhr.open('POST', url)
    xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.setRequestHeader('Authorization', 'Bearer ' + token)
  let contentLength = 0
  let bufferString = ''
  const dataPrefix = 'data: '
  const doneData = `${dataPrefix}[DONE]`
  let content = ''
  let usage
  let closed = false
  xhr.onprogress = e => {
    if (closed) return
    const chunk = xhr.responseText.substring(contentLength)
    contentLength += chunk.length
    bufferString += chunk 
    let end = bufferString.lastIndexOf('\n\n')
      ////console.log('end', end)
    let dataString
    if (end > 0) {
      dataString = bufferString.substring(0, end + 2)
      bufferString = bufferString.substring(end + 2)
    }
    if (dataString) {
      //console.log('dataString', dataString)
      try {
        const isFinal = dataString.includes(doneData)
        const dataJsonLines = dataString
              .split(doneData)
              .join('')
              .trim()
              .split(dataPrefix)
              .filter((v) => !!v) // Remove empty lines
        const contentSnippets = dataJsonLines.map((dataJson) => {
          const parsed = JSON.parse(dataJson)
          //console.log(parsed)
          const { choices } = parsed
          if (parsed.usage) {
            usage = parsed.usage
          }
          const [choice] = choices
          if (choice && choice.delta.text) {
            choice.delta.content = choice.delta.text
            delete choice.delta.text
          }
          if (choice && choice.delta && choice.delta.content) {
            content += choice.delta.content
          }
          return parsed
        })
        for (const snip of contentSnippets) {
          //console.log("content so far", content)
          onContent(snip)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
  xhr.onreadystatechange = () => {
    var _a, _b;
    if (xhr.readyState === XMLHttpRequest.DONE) {
      const status = xhr.status;
      // In local files, status is 0 upon success in Mozilla Firefox
      // See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readystatechange_event#examples
      if (status === 0 || (status >= 200 && status < 400)) {
        if (closed) return
        if (onDone) onDone(xhr);
        if (resolve) resolve({
          message: {
            role: 'assistant',
            content,
          },
          usage
        })
      }
      else {
        const error = new Error(`Error processing stream completion (XHR readyState ${xhr.readyState}, status ${xhr.status}).`)
        onError(error, xhr.status, xhr)
          if (reject) reject(error)
      }
    }
  }
  xhr.onerror = (event) => {
    onError(new Error(`Error processing stream completion (XHR readyState ${xhr.readyState}, status ${xhr.status}).`), xhr.status, xhr)
  }
  xhr.send(JSON.stringify(data))
  return {
    abort: () => {
      closed = true
      xhr.abort()
    }
  }
}

