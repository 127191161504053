import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { getComponents, pasteText, SimpleIcon, SimpleButton, DeleteButton, SearchField } from '../ChatGPT'
import { Markdown } from '../ChatGPT/Markdown.js'
import { fromNow, hash, formatTokens, capitalize, delay, startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, endOfDay} from '../../classes/Util.js'
import NewFolder from '../../assets/Icons/NewFolder.svg'
import Folder from '../../assets/Icons/Folder.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import Right from '../../assets/Icons/Forward.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Paste from '../../assets/Icons/Paste.svg'
import Edit from '../../assets/Icons/Edit.svg'
import Cut from '../../assets/Icons/Share.svg'
import Save from '../../assets/Icons/SaveCommand.svg'
import ClickAwayListener from 'react-click-away-listener'
import { Calendar } from '../Home/Usage.js'
import { ActionMenu } from '../Home/ActionMenu.js'
import { Toolsets } from '../Tools'
import './index.css'

let selectedSystemPrompt

class SystemPrompt extends Component {

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  editTitle = () => {
    this.setState({
      title: this.props.systemPrompt.title,
      editingTitle: true
    }, () => {
      this.input.focus()
    })
  }

  commitEdit = async () => {
    if (this.state.editingTitle) {
      await this.props.save(this.state.title)
      this.stopEditingTitle()
    }
  }

  stopEditingTitle = () => {
    this.setState({editingTitle: false})
  }

  onChangeTitle = title => {
    this.setState({
      title
    })
  }

  setInput = ref => {
    this.input = ref
    if (ref && isDesktop()) {
      ref.focus()
    }
  }

  render() {
    const onDrop = event => {
      this.props.onDrop(this)
    }
    const onDragStart = event => {
      this.props.onDrag(this)
    }
    const systemPrompt = this.props.systemPrompt
    const { isFolder } = systemPrompt
    const onClick = () => {
      this.props.selectSystemPrompt()
    }
    let trash = this.props.trash
    let date
    date = fromNow(systemPrompt.lastUpdated)
    let deleteButton = <DeleteButton label='Delete' trash={trash}/>
    let dateComp
    dateComp = <div className='systemPromptDate'>{date}</div>
    let components = getComponents()
    let { usage } = systemPrompt
    let dollars
    if (usage && usage.total) {
      dollars = '$'+formatPrice(usage.total)
    }
    let { title, content } = systemPrompt
    let titleDiv
    let onBlur
    const para = content.indexOf('\n\n')
    if (para > 0) {
      content = content.substring(0, para) + "..."
    } 
    const sentence = content.indexOf('. ')
    if (sentence > 0 && sentence + 5 < content.length) {
      content = content.substring(0, sentence) + "..."
    }
    
    if (isMobile()) {
      onBlur = async () => {
        this.commitEdit()
      }
    }
    let onKeyDown
    if (isDesktop()) {
      onKeyDown = event => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          this.commitEdit()
        } else if (event.key === 'Escape') {
          event.preventDefault()
          this.stopEditingTitle()
        }
      }
    }
    if (this.state.editingTitle) {
      titleDiv = <ClickAwayListener mouseEvent={'mousedown'} onClickAway={this.stopEditingTitle}>
                   <div className='systemPromptTitle'>
                     <input
                       onBlur={onBlur}
                       onKeyDown={onKeyDown}
                       ref={this.setInput}
                       onChange={e=> this.onChangeTitle(e.target.value)}
                       value={this.state.title}/>
                   </div>
                 </ClickAwayListener>
    } else {
      titleDiv = <div className='systemPromptTitle'>
        {title}
      </div>
    }
    const select = this.props.select
    const action = select || this.props.open
    const contentDiv = <Markdown components={components}>{content}</Markdown>
    const copy = async () => {
      await this.props.copy(this.props.systemPrompt)
      await delay(0.3)
    }
    const cut = async () => {
      await this.props.cut(this.props.folder, this.props.systemPrompt)
    }
    const actions = []
    if (this.props.folder) {
      actions.push({
        icon: Cut,
        label: 'Cut',
        action: cut
      })
    }
    actions.push({
      icon: Copy,
      label: 'Copy',
      action: copy
    })
    if (this.props.systemPrompt.isFolder) {
      actions.push({
        icon: Edit,
        label: 'Edit',
        action: this.props.edit || this.editTitle
      })
    }
    actions.push({
      button: () => <div className='deleteButtonHolder'>{deleteButton}</div>
    })

    let icon
    if (isFolder) {
      icon = Folder
    } else {
      icon = UserSaid
    }
    let className='systemPromptTask'
    if (this.props.isSelected) {
      className += ' systemPromptTaskSelected'
    }
    return <div
             key={systemPrompt.id}
             draggable={!this.state.editingTitle}
             onDragStart={onDragStart}
             onClick={this.props.open}             
             className={className}>
             <div  className='systemPromptLeft' >
               <div className='systemPromptLeftTopRow'>
                 <div className='systemPromptLeftTopRowLeft'>
	           <SimpleIcon src={icon}/>
                   {titleDiv}
                 </div>
                 <div className='systemPromptTopRowRight'>{dateComp}</div>
               </div>
               <div className='systemPromptMiddle'>
                 <div className='systemPromptMiddleDescription'>
                   <Markdown components={components}>{content}</Markdown>
                 </div>
               </div>
             
             </div>
             <div className='systemPromptRight'>
               <ActionMenu className='systemPromptActions' actions={actions}/>
               <SimpleButton icon={Right} action={action}/>
             </div>
           </div>
  }
}

class SystemPromptEditor extends BnSubpage {

  setEditor = ref => {
    if (this.editor !== ref) {
      this.editor = ref
      if (ref) {
        ref.innerText = this.props.systemPrompt.content
      }
    }
  }

  componentDidMount() {
  }
  
  edit = () => {
    this.setState({
      editing: true
    }, () => {
      this.editor.focus()
    })
  }

  stopEditing = () => {
    this.setState({
      editing: false
    })
  }
  
  save = async () => {
    await this.props.save(this.editor ? this.editor.innerText : this.props.systemPrompt.content)
    this.stopEditing()
  }

  selectTools = () => {
    let cut
    const onCreate = (comp) => {
      
    }
    let copy
    const select = (toolset) => {
      this.back()
    }
    this.setState({
      subpage: () => <Toolsets
                       select={select}
                       title={"Toolsets"}
                       cut={cut}
                       onCreate={onCreate}
                       copy={copy}
                       clipboard={this.props.toolClipboard}
                       toolsets={this.props.toolsets}
                       me={this.props.me}
                       back={this.back}/>
      
    })
  }

  renderContent() {
    const copy = async () => {
      try {
        navigator.clipboard.writeText(this.editor.innerText)
      } catch (err) {
        console.error(err)
        //console.log(text)
      }
      this.props.copy(this.props.systemPrompt)
      await delay(0.5)
    }
    const trash = async () => {
      await this.props.me.deleteSystemPrompt(this.props.systemPrompt.id)
      if (isMobile() || !this.props.openDetail) {
        this.props.back()
      } else {
        this.setState({
          renderEditor: null
        })
      }
    }
    const editTitle = () => {
    }
    const copyTitle = () => {
    }
    const setTools = () => {
      this.selectTools()
    }

    const Section = ({ name, content, actions, style, row2 }) => {
      return <div className='systemPromptEditorSection' style={style}>
               <div className='systemPromptEditorSectionRow1'>
                 {name &&<div className='systemPromptEditorSectionTitle'>
                           {name}
                         </div>}
                 <div className='systemPromptEditorSectionContent'>
                   {content}
                 </div>
                 <div className='systemPromptEditorSectionRight'>
                   {
                     actions.map(x => {
                       const { icon, action } = x
                       return <SimpleButton icon={icon} action={action}/>
                     })
                   }
                 </div>
               </div>
               {
                 row2 && <div className='systemPromptEditorSectionRow2'>
                           {row2}
                         </div>
               }
             </div>
    }
    let titleContent = <div className='systemPromptTitleEditor'>
                         {this.props.systemPrompt.title}
                       </div>
    return <div className='systemPromptEditorContainer'>
             <div className='systemPromptEditorBg'>
               <div className='systemPromptEditorSections'>
                 <Section name=''
                          content={titleContent}
                          actions={
                          [
                            {
                              icon: Edit,
                              action: editTitle
                            },
                          ]
                        }/>
               <Section name='Tools' actions={
                          [
                            {
                              icon: Right,
                              action: setTools
                            },
                          ]
                        }/>
                 <Section name='Instructions'
                          style={{flexGrow: 1, maxHeight: 'calc(100% - 125px)'}}
                          row2={
                            <div className='systemPromptEditorContainerEditor'>
                              <div ref={this.setEditor}
                                   onPaste={pasteText}
                                   className='systemPromptEditor'
                                   contentEditable={this.state.editing}/>
                              </div>
                          }
                          actions={
                          [
                            {
                              icon: Edit,
                              action: this.edit
                            },
                          ]
                        }/>
               </div>
           </div>
    <div className='systemPromptEditorButtons'>
      <SimpleButton label={'Save'} icon={Save} action={this.save}/>
      <div className='systemPromptDelete'>
        <DeleteButton trash={trash}/>
      </div>
    </div>
    </div>
  }
}

export class SystemPrompts extends BnSubpage {

  onDayChange = day => {
    this.setState({
      calDay: day
    })
  }

  onViewChange = view => {
    this.setState({
      calView: view
    })
  }
  
  setScrollRef = ref => {
    if (ref) {
      const scrollContainer = ref
      const hitBottom = () => {
        const isAtBottom = scrollContainer.scrollHeight - scrollContainer.clientHeight <= scrollContainer.scrollTop + 1;
        //////console.log({isAtBottom})
        return isAtBottom
      }
      ref.onscroll = () => {
        if (hitBottom()) {
          this.checkScroll()
        }
      }
    }
  }

  openSystemPrompt = systemPrompt => {
    const back = async () => {
      this.state.openPrompt = null
      this.state.renderEditor = null
      this.back()
    }
    this.state.openPrompt = systemPrompt
    if (systemPrompt.isFolder) {
      const cut = this.props.cut
      const copy = this.props.copy
      this.setState({
        subpage: () => <SystemPrompts
                         openDetail={this.props.openDetail}
                         key={systemPrompt.id}
                         title={"System Prompts"}
                         folder={systemPrompt}
                         cut={cut}
                         copy={copy}
                         clipboard={this.props.clipboard}
                         onDrag={this.props.onDrag}
                         onDrop={this.props.onDrop}
                         me={this.props.me}
                         back={back}/>
      })
      return
    }
    const commitEdit = async (content) => {
      await this.saveSystemPrompt(systemPrompt)
      systemPrompt.content = content
      this.saveSystemPrompt(systemPrompt)
      this.back()
    }
    const render = () => <SystemPromptEditor
                           save={commitEdit}
                           me={this.props.me}
                           title={systemPrompt.title}
                           copy={this.props.copy}
                           systemPrompt={systemPrompt}
                           toolsets={this.props.toolsets}
                           back={(isMobile() || !this.props.openDetail) && this.back}/>
    if (isMobile() || !this.props.openDetail) {
      this.setState({
        subpage: render
      })
    } else {
      this.setState({
        renderEditor: render
      })
    }
  }

  createSystemPromptFolder = async () => {
    await this.props.me.createNewSystemPromptFolder(this.props.folder)
  }

  createNewSystemPrompt = async () => {
    return await this.props.me.createNewSystemPrompt(this.props.folder)
  }

  saveSystemPrompt = async (systemPrompt) => {
    return await this.props.me.saveSystemPrompt(systemPrompt)
  }


  deleteSystemPrompt = async (systemPrompt) => {
    return await this.props.me.deleteSystemPrompt(systemPrompt.id)
  }

  systemPrompts = {}
  componentDidMount() {
    debugger
    if (this.props.folder) {
      this.sub = this.props.me.observeSystemPrompts({folder: this.props.folder}).subscribe(change => {
        debugger
        const { type, systemPrompt } = change
        if (type === 'removed') {
          delete this.systemPrompts[systemPrompt.id]
        } else {
          this.systemPrompts[systemPrompt.id] = systemPrompt
        }
        this.updateLater('systemPrompts', this.updateSystemPrompts)
      })
    }
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  updateSystemPrompts = () => {
    const systemPrompts = Object.values(this.systemPrompts)
    systemPrompts.sort((x, y) => y.lastUpdated - x.lastUpdated)
    const events = systemPrompts.map((elem) => {
      const { id, lastUpdated } = elem
      const start = new Date(lastUpdated)
      return {
        id,
        start,
        text: '',
        daily: elem
      }
    })
    debugger
    this.setState({
      systemPrompts,
      events
    })
  }
  
  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  checkScroll = () => {
    if (this.props.checkScrollBack) {
      this.props.checkScrollBack()
    }
    const visibleSystemPromptCount = Math.min(this.state.visibleSystemPromptCount + 10, this.props.systemPrompts.length)
    this.setState({
      visibleSystemPromptCount
    })
  }

  onSearch = searchTerm => {
  }
    
  renderContent() {
    const output = [this.renderListView()]
    if (this.state.renderEditor) {
      output.push(this.props.openDetail(this.state.renderEditor))
    }
    return output
  }
  
  renderListView() {
    let systemPrompts
    let events
    if (this.props.folder) {
      systemPrompts = this.state.systemPrompts || []
      events = this.state.events
    } else {
      systemPrompts = this.props.systemPrompts || []
      events = systemPrompts.map((elem) => {
        const { id, lastUpdated } = elem
      const start = new Date(lastUpdated)
        return {
          id,
        start,
          text: '',
          daily: elem
        }
      })
    }
    let filt
    switch (this.state.calView) {
      case 'recent':
        break
      case 'day':
        filt = job => {
          const { lastUpdated } = job
          return startOfDay(this.state.calDay) < lastUpdated && endOfDay(this.state.calDay) > lastUpdated
        }
        break
      case 'month':
        filt = job => {
          const { lastUpdated } = job
          return startOfMonth(this.state.calDay) < lastUpdated && endOfMonth(this.state.calDay) > lastUpdated
        }
        break
    }
    if (filt) {
      systemPrompts = systemPrompts.filter(filt)
    }
    let className= 'systemPromptTopLine'
    if (this.props.select) {
      //className += ' systemPromptTopLineSelect'
    }
    const onDrop = event => {
      handleDataTransfer(event, event.dataTransfer)
    }
    const handleDataTransfer = async (event, transfer) => {
      const models = []//this.modelsView.getSelectedModelIds()
      if (transfer.files.length > 0) {
        event.preventDefault();
        const parent = this.props.folder ? this.props.folder.id : undefined
        for (const file of transfer.files) {
          await this.props.me.uploadSystemPrompts({parent, file, models})
        }
        return true;
      } else {
        const uriList = transfer.getData('text/uri-list')
        if (uriList) {
          event.preventDefault()
          // Split the URI list by newline to handle multiple URLs
          const urls = uriList.split('\n').filter(url => url.trim() !== '')
          console.log('URLs detected:', urls)
          for (const url of urls) {
            await this.props.me.uploadSystemPrompts({url, models})
          }
        }
      }
    }
    const onDragSystemPrompt = systemPrompt => {
      selectedSystemPrompt = systemPrompt
    }
    const onDropSystemPrompt = async systemPrompt => {
      const src = this.state.selectedSystemPrompt
      const target = systemPrompt
      if (src !== target) {
        if (target.props.systemPrompt.isFolder) {
          if (await this.props.me.addSystemPromptParent({child: src.props.systemPrompt, parent: target.props.systemPrompt.id})) {
            this.openFolder(target.props.systemPrompt)
          }
        }
      }
    }
    const actions = [
      {
        label: "New System Prompt",
        icon: UserSaid,
        action: this.createNewSystemPrompt
      },
      {
        label: "New Folder",
        icon: NewFolder,
        action: this.createSystemPromptFolder,
      }]
    if (this.props.clipboard) {
      const paste = async () => {
        await this.props.me.addSystemPromptParent(
          { parent: this.props.folder ? this.props.folder.id: undefined,
            child: this.props.clipboard
          }
        )
      }
      actions.push({
        label: "Paste",
        icon: Paste,
        action: paste
        })
    }
    return <div className={'chatGptSystemPromptsMenu'} ref={this.setScrollRef}>
             <div className={className}>
               <ActionMenu className='systemPromptActions' position="bottom left"  actions={actions}/>
               <SearchField onSearch={this.onSearch} me={this.props.me}/>
             </div>
              <div className='systemPromptsCal'>
               <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={events} onDayChange={this.onDayChange} initialView={'recent'} viewSelection={['recent', 'day', 'month']} />
             </div>
            <div className='chatGptSystemPromptsMenuSystemPrompts'>
               {
                 systemPrompts.map(systemPrompt => {
                   let selectSystemPrompt
                   if (this.props.select) {
                     selectSystemPrompt = () => this.props.select(systemPrompt)
                   }
                   const openSystemPrompt = async () => {
                     this.openSystemPrompt(systemPrompt)
                   }
                   const saveTitle = async (title) => {
                     systemPrompt.title = title
                     debugger
                     await this.saveSystemPrompt(systemPrompt)
                   }
                   const trash = async () => {
                     await this.deleteSystemPrompt(systemPrompt)
                     this.forceUpdate()
                   }
                   const isSelected= this.state.openPrompt && (this.state.openPrompt.id === systemPrompt.id)
                   return <SystemPrompt
                            openDetail={this.props.openDetail}
                            onDrag={onDragSystemPrompt}
                            onDrop={onDropSystemPrompt}
                            folder={this.props.folder}
                            key={systemPrompt.id}
                            select={selectSystemPrompt}
                            isSelected={isSelected}
                            systemPrompt={systemPrompt}
                            save={saveTitle}
                            copy={this.props.copy}
                            cut={this.props.cut}
                            edit={this.state.openPrompt ? null: openSystemPrompt}
                            open={openSystemPrompt}
                            trash={trash}/>
                 })
               }
             </div>
           </div>
  }
  

}


