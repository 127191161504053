import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { catchError, filter, map, flatMap, take, merge  } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { parsePhoneNumber, BnInputField, BnInputFieldSeparator } from '../TextInput'
import { getComponents, pasteText, SimpleIcon, SimpleButton, DeleteButton, GearButton, SearchField, FileChooser} from '../ChatGPT'
import { Markdown } from '../ChatGPT/Markdown.js'
import { fromNow, hash, formatTokens, capitalize, delay, startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, endOfDay, listOnKeyDown} from '../../classes/Util.js'
import NewFolder from '../../assets/Icons/NewFolder.svg'
import Folder from '../../assets/Icons/Folder.svg'
import HF from '../../assets/Icons/HuggingFace.svg'
import Gear from '../../assets/Icons/Settings.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Right from '../../assets/Icons/Forward.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Copy from '../../assets/Icons/Copy.svg'
import OpenFile from '../../assets/Icons/OpenFile.svg'
import HuggingFace from '../../assets/Icons/HuggingFace.svg'
import ToolServerIcon from '../../assets/Icons/ToolServer.svg'
import Paste from '../../assets/Icons/Paste.svg'
import Edit from '../../assets/Icons/Edit.svg'
import Cut from '../../assets/Icons/Share.svg'
import Save from '../../assets/Icons/SaveCommand.svg'
import ClickAwayListener from 'react-click-away-listener'
import { Calendar } from '../Home/Usage.js'
import { HuggingFaceDatasets } from '../Home/Datasets.js'
import { ActionMenu } from '../Home/ActionMenu.js'
import './index.css'

let selectedToolset

function convertSchemaToTypescriptFunction(schema) {
  if (!schema || schema.type !== 'function' || !schema.function) {
    throw new Error('Invalid schema: Must be a function schema');
  }

  const func = schema.function;
  let doc = `/**\n * @${func.name} - ${func.description}\n*`;

  // Build the parameter list and interface for the TypeScript function
  let params = ''
  let sep = ''
  let docSep = ''
  if (func.parameters && func.parameters.properties) {
    for (const [paramName, paramDetails] of Object.entries(func.parameters.properties)) {
      const optional = func.parameters.required && !func.parameters.required.includes(paramName) ? '?' : '';
      doc += docSep
      doc += `\n* @${paramName} ${paramDetails.description ? (' - ' + paramDetails.description) : ''}`
      params += sep
      sep = ', '
      params += `${paramName}${optional}: ${convertSchemaToTypescript(paramDetails)}`
    }
  }

  // Add the function parameters to the function signature
  doc += `\n**/\n\nfunction ${func.name}(${params}): any;\n`;

  // Combine the interface and function declaration
  return doc
}

function convertSchemaToTypescript(schema) {
  // Helper function to convert each schema definition to TypeScript
  const convertDefinition = (definition) => {
    if (!definition) {
      return 'any';
    }

    if (definition.$ref) {
      return definition.$ref.replace('#/definitions/', '');
    }

    switch (definition.type) {
      case 'string':
        return 'string';
      case 'number':
        return 'number';
      case 'integer':
        return 'number';
      case 'boolean':
        return 'boolean';
      case 'object':
        const properties = Object.entries(definition.properties || {})
          .map(([key, value]) => {
            const optional = definition.required && !definition.required.includes(key) ? '?' : '';
            return `${key}${optional}: ${convertDefinition(value)};`;
          })
          .join(' ');
        return `{ ${properties} }`;
      case 'array':
        return `${convertDefinition(definition.items)}[]`;
      case 'null':
        return 'null';
      default:
        return 'any';
    }
  };

  // Handle combining schemas with oneOf, anyOf, allOf
  const combineSchemas = (schemas, combiner) => {
    if (!schemas) return 'any';
    return schemas.map(convertDefinition).join(combiner);
  };

  // Main conversion starts here
  if (schema.oneOf) {
    return combineSchemas(schema.oneOf, ' | ');
  } else if (schema.anyOf) {
    return combineSchemas(schema.anyOf, ' | ');
  } else if (schema.allOf) {
    return combineSchemas(schema.allOf, ' & ');
  } else if (schema.enum) {
    return schema.enum.map((value) => JSON.stringify(value)).join(' | ');
  } else {
    return convertDefinition(schema);
  }
}

const SimpleInput = props => {
  const { form, formErr, onChange, name, type, autocomplete, label, placeholder } = props
  return <div cassName='simpleInput'>
           <div className='simpleInputTitle'>{label || placeholder}</div>
           <BnInputField  name={name} label={placeholder} formErr={formErr} form={form} type={type} onChange={onChange} autoComplete={autocomplete} busy={form.busy}/>
         </div>
}
  

export class ToolServer extends BnSubpage {

  componentDidMount() {
    const { servers, paths, toolset, name, auth } = this.props.toolServer
    this.set('name', name)
    this.set('auth', auth || '')
    this.set('endpoint', servers.length > 0 ? servers[0].url : '')
  }
  
  renderContent() {
    const { servers, paths, toolset, name, auth, id } = this.props.toolServer
    const save = async () => {
      const { auth, name, endpoint } = this.getForm()
      await this.props.save(id, { auth })
    }
    return <div className='toolServer'>
             <div className='toolServerFields'>
               <SimpleButton icon={ToolServerIcon} label="Tool Server"/>
               <div className='toolServerName'>
                 <SimpleInput form={this.getForm()} onChange={this.onChange} name='name' placeholder={'Name'}/>
               </div>
               <div className='toolServerEndpoints'>
                 <SimpleInput form={this.getForm()} onChange={this.onChange} name='endpoint' placeholder={'Server'}/>
               </div>
               <div className='toolServerAuth'>
                 <SimpleInput form={this.getForm()} onChange={this.onChange} name='auth' placeholder={'Authorization'}/>
               </div>
               <div className='toolServerButtons'>
                 <SimpleButton label='Save' icon={Save} action={save}/>
               </div>
             </div>
             <div className='toolServerTools'>
               Toolset <SimpleButton icon={Right}/>
             </div>
           </div>
  }
}


class Toolset extends Component {

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  editTitle = () => {
    this.setState({
      title: this.props.toolset.name,
      editingTitle: true
    }, () => {
      this.input.focus()
    })
  }

  commitEdit = async () => {
    if (this.state.editingTitle) {
      await this.props.save(this.state.name)
      this.stopEditingTitle()
    }
  }

  stopEditingTitle = () => {
    this.setState({editingTitle: false})
  }

  onChangeTitle = title => {
    this.setState({
      title
    })
  }

  setInput = ref => {
    this.input = ref
    if (ref && isDesktop()) {
      ref.focus()
    }
  }

  render() {
    const onDrop = event => {
      this.props.onDrop(this)
    }
    const onDragStart = event => {
      this.props.onDrag(this)
    }
    const toolset = this.props.toolset
    const { isFolder } = toolset
    const onClick = () => {
      this.props.selectToolset()
    }
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    let trash = this.props.trash
    let date
    date = fromNow(toolset.lastUpdated)
    let deleteButton = <DeleteButton label='Delete' trash={trash}/>
    let dateComp
    dateComp = <div className='toolsetDate'>{date}</div>
    let components = null
    let { usage } = toolset
    let dollars
    if (usage && usage.total) {
      dollars = '$'+formatPrice(usage.total)
    }
    let { name, tools } = toolset
    console.log("TOOLSET", toolset)
    let title = name
    let titleDiv
    let onBlur
    if (isMobile()) {
      onBlur = async () => {
        this.commitEdit()
      }
    }
    let onKeyDown
    if (isDesktop()) {
      onKeyDown = event => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          this.commitEdit()
        } else if (event.key === 'Escape') {
          event.preventDefault()
          this.stopEditingTitle()
        }
      }
    }
    if (this.state.editingTitle) {
      titleDiv = <ClickAwayListener mouseEvent={'mousedown'} onClickAway={this.stopEditingTitle}>
                   <div className='toolsetTitle'>
                     <input
                       onBlur={onBlur}
                       onKeyDown={onKeyDown}
                       ref={this.setInput}
                       onChange={e=> this.onChangeTitle(e.target.value)}
                       value={this.state.name}/>
                   </div>
                 </ClickAwayListener>
    } else {
      titleDiv = <div className='toolsetTitle'>
                   {title || (toolset.isFolder ? "New Folder" : this.props.emptyTitle) }
      </div>
    }
    const select = this.props.select
    const action = select || this.props.open
    const contentDiv = this.props.toolset.summary
    const copy = async () => {
      await this.props.copy(this.props.toolset)
      await delay(0.3)
    }
    const cut = async () => {
      await this.props.cut(this.props.folder, this.props.toolset)
    }
    const actions = []
    if (this.props.folder) {
      actions.push({
        icon: Cut,
        label: 'Cut',
        action: cut
      })
    }
    actions.push({
      icon: Copy,
      label: 'Copy',
      action: copy
    })
    actions.push({
      icon: Edit,
      label: 'Edit',
      action: this.props.edit || this.editTitle
    })
    actions.push({
      button: () => <div className='deleteButtonHolder'>{deleteButton}</div>
    })

    let icon
    let legacyIconSize
    if (isFolder) {
      icon = Folder
    } else {
      icon = Gear
      legacyIconSize = true
    }
    const render = this.props.renderTask
    if (render) {
      return render({
        file: toolset,
        draggable: !this.state.editingTitle,
        onDragStart,
        titleDiv,
        contentDiv,
        onClick: this.props.open,
        actions,
        action
      })
    }
    return <div
             key={toolset.id}
             draggable={!this.state.editingTitle}
             onDragStart={onDragStart}
             className='toolsetTask'>
             <div  className='toolsetLeft'>
               <div className='toolsetLeftTopRow'>
                 <div className='toolsetLeftTopRowLeft'>
	           <SimpleIcon src={icon} legacyIconSize={legacyIconSize}/>
                   {titleDiv}
                 </div>
                 <div className='toolsetTopRowRight'>{dateComp}</div>
               </div>
               <div className='toolsetMiddle' onClick={this.props.open}>
                 <div className='toolsetMiddleDescription'>
                   {contentDiv}
                 </div>
               </div>
             
             </div>
             <div className='toolsetRight'>
               <ActionMenu className='toolsetActions' actions={actions}/>
               <SimpleButton icon={Right} action={action}/>
             </div>
           </div>
  }
}

class ToolsetEditor extends BnSubpage {

  setEditor = ref => {
    if (this.editor !== ref) {
      this.editor = ref
    }
  }

  componentDidMount() {
    //this.editor.innerText = this.props.toolset.content
  }
  
  edit = () => {
    this.setState({
      editing: true
    }, () => {
      this.editor.focus()
    })
  }

  stopEditing = () => {
    this.setState({
      editing: false
    })
  }
  
  save = async () => {
    await this.props.save(this.editor ? this.editor.innerText : this.props.toolset.content)
    this.stopEditing()
  }

  componentDidMount() {
    this.setState({
      selectedTool: this.props.toolset.tools[0]
    })
  }

  renderTool = tool => {
    let code
    if (true) {
      code = convertSchemaToTypescriptFunction(tool)
    } else {
      const json = JSON.stringify(tool, null, '    ')
      code = json
    }
    const components = getComponents()
    return <div className='toolsetTool'>
             <pre><code>{code}</code></pre>
           </div>
    
  }

  renderContent() {
    const copy = async () => {
      try {
        navigator.clipboard.writeText(this.editor.innerText)
      } catch (err) {
        console.error(err)
        //console.log(text)
      }
      this.props.copy(this.props.toolset)
      await delay(0.5)
    }
    const trash = async () => {
      await this.props.me.deleteToolset(this.props.toolset.id)
      if (isMobile() || !this.props.openDetail) {
        this.props.back()
      } else {
        this.setState({
          renderEditor: null
        })
      }
    }
    console.log('toolset', this.props.toolset)
    const tools = this.props.toolset.tools || []
    const renderTool = tool => {
      if (!tool) {
        return <div className='toolsetEditorContainer'>
          </div>
      }
      const isSelected = this.state.selectedTool === tool
      let className = 'toolsetEditorContainer'
      return <div className={className}>
               <div className='desktopToolsetDetailsTitle'>
                 {tool.function.name}
               </div>
               <div className='toolsetEditorBg'>
                 <div className='toolsetEditorLeft'>
                   {
                     this.renderTool(tool)
                   }
                 </div>
                 <div className='toolsetEditorRight'>
                   <SimpleButton icon={Edit} action={this.edit}/>
                   <SimpleButton icon={Copy} action={copy}/>
                 </div>
               </div>
             <div className='toolsetEditorButtons'>
               <SimpleButton label={'Save'} icon={Save} action={this.save}/>
               <div className='toolsetDelete'>
                 <DeleteButton trash={trash}/>
               </div>
             </div>
             </div>
    }
    let detail
    if (isDesktop() && this.props.openDetail) {
      detail = this.props.openDetail(() => renderTool(this.state.selectedTool))
    }
    let onKeyDown
    let autoFocus
    let tabIndex
    if (isDesktop()) {
      onKeyDown = listOnKeyDown({
        enter: () => {
        },
        getIndex: () => {
          //////debugger
          let selected
          if (this.state.selectedTool) {
            selected = tools.find(x => x.name === this.state.selectedTool.name)
          }
          if (selected) {
            return tools.indexOf(selected)
          }
          return -1
        },
        getLength: () => tools.length,
        setIndex: index => {
          //////debugger
          const newSelection = tools[index]
          if (newSelection && newSelection.name !== this.state.selectedTool.name) {
            this.setState({
              selectedTool: newSelection
            })
          }
        }
      })
      tabIndex = 2
      autoFocus = true
    }
    const onFocus = e => {

    }
    return <div className='toolsetEditorPage'
                onKeyDown={onKeyDown}
                autoFocus={autoFocus}
                tabIndex={tabIndex}
                onFocus={onFocus}
           >
             {
               tools.map(tool => {
                 let titleDiv = tool.function.name
                 let contentDiv = tool.function.description
                 let icon= Gear
                 let legacyIconSize= true
                 let actions = []
                 let action = async () => {
                 }
                 const select = () => {
                   this.setState({
                     selectedTool: tool
                   })
                 }
                 const isSelected = this.state.selectedTool === tool
                 let className = 'toolsetTask'
                 if (isSelected) {
                   className += ' toolsetTaskSelected'
                 }
                 return <div className={className} onClick={select}>
                          <div  className='toolsetLeft'>
                            <div className='toolsetLeftTopRow'>
                              <div className='toolsetLeftTopRowLeft'>
	                        <SimpleIcon src={icon} legacyIconSize={legacyIconSize}/>
                                {titleDiv}
                              </div>
                            </div>
                            <div className='toolsetMiddle' onClick={this.props.open}>
                              <div className='toolsetMiddleDescription'>
                                {contentDiv}
                              </div>
                            </div>
                            
                          </div>
                          <div className='toolsetRight'>
                            <ActionMenu className='toolsetActions' actions={actions}/>
                            <SimpleButton icon={Right} action={action}/>
                          </div>
                        </div>
               })
             }
             {detail}
           </div>
  }
}

export class Toolsets extends BnSubpage {

  onDayChange = day => {
    this.setState({
      calDay: day
    })
  }

  onViewChange = view => {
    this.setState({
      calView: view
    })
  }
  
  setScrollRef = ref => {
    if (ref) {
      const scrollContainer = ref
      const hitBottom = () => {
        const isAtBottom = scrollContainer.scrollHeight - scrollContainer.clientHeight <= scrollContainer.scrollTop + 1;
        //////console.log({isAtBottom})
        return isAtBottom
      }
      ref.onscroll = () => {
        if (hitBottom()) {
          this.checkScroll()
        }
      }
    }
  }

  renderHuggingFace = () => {
    const importDataset = async opts => {
      const { dataset, numSamples } = opts
      const { url } = dataset
      this.back()
      await this.props.me.uploadDataset({
        url,
        parent: this.props.parent ? this.props.parent.id : undefined,
        numSamples 
      })
    }
    let back
    let cancel
    if (isMobile()) {
      back = this.back
    } else {
      cancel = this.back
    }
    return  () => <HuggingFaceDatasets
                    me={this.props.me}
                    openDetail={this.props.openDetail}
                    importDataset={importDataset}
                    title={'HuggingFace'}
                    cancel={cancel}
                    back={back}/>
  }

  openHuggingFace = () => {
    let subpage
    subpage = this.renderHuggingFace()
    this.setState({
      huggingFaceOpen: true,
      subpage
    })
  }
  
  openToolset = toolset => {
    this.state.openPrompt = toolset
    const commitEdit = async (content) => {
      await this.saveToolset(toolset)
      toolset.content = content
      this.saveToolset(toolset)
      this.back()
    }
    let render
    let back =  this.back
    if (toolset.isFolder) {
      const cut = this.props.cut
      const copy = this.props.copy
      render = () => <Toolsets
                       emptyTitle={this.props.emptyTitle}
                       newButton={this.props.newButton}
                       select={this.props.select}
                       openDetail={this.props.openDetail}
                       key={toolset.id}
                       title={toolset.name || toolset.name}
                       folder={toolset}
                       cut={cut}
                       copy={copy}
                       clipboard={this.props.clipboard}
                       onDrag={this.props.onDrag}
                       onDrop={this.props.onDrop}
                       me={this.props.me}
                       renderTask={this.props.renderTask}
                       observeToolsets={this.props.observeToolsets}
                       createNewToolset={this.props.createNewToolset}
                       createToolsetFolder={this.props.createToolsetFolder}
                       saveToolset={this.props.saveToolset}
                       deleteToolset={this.props.deleteToolset}
                       uploadToolset={this.props.uploadToolset}
                       addParent={this.props.addParent}
                       back={back}/>
    } else {
      //debugger
      if (this.props.select) {
        return this.props.select(toolset)
      }
      if (this.props.renderFile) {
        render = () => this.props.renderFile({
          me: this.props.me,
          file: toolset,
          back: this.back
        })
      } else {
        render = () => <ToolsetEditor
                         openDetail={this.props.openDetail}
                         save={commitEdit}
                         me={this.props.me}
                         title={toolset.name}
                         copy={this.props.copy}
                         toolset={toolset}
                         back={this.back}/>
      }        
    }
    this.setState({
      subpage: render
    })
  }

  createToolsetFolder = async () => {
    await this.props.createNewToolsetFolder({parentFolder: this.props.folder})
  }

  createNewToolset = async () => {
    return await this.props.createNewToolset({parentFolder: this.props.folder})
  }

  saveToolset = async (toolset) => {
    return await this.props.saveToolset({id: toolset.id, updates: toolset})
  }

  deleteToolset = async (toolset) => {
    return await this.props.deleteToolset(toolset.id)
  }

  toolsets = {}
  componentDidMount() {
    //debugger
    if (this.props.folder) {
      this.sub = this.props.observeToolsets({folder: this.props.folder}).subscribe(change => {
        //debugger
        const { type, toolset } = change
        if (type === 'removed') {
          delete this.toolsets[toolset.id]
        } else {
          this.toolsets[toolset.id] = toolset
        }
        this.updateLater('toolsets', this.updateToolsets)
      })
    }
    if (this.props.onCreate) {
      this.props.onCreate(this)
    }
  }

  updateToolsets = () => {
    const toolsets = Object.values(this.toolsets)
    toolsets.sort((x, y) => y.lastUpdated - x.lastUpdated)
    const events = toolsets.map((elem) => {
      const { id, lastUpdated } = elem
      const start = new Date(lastUpdated)
      return {
        id,
        start,
        text: '',
        daily: elem
      }
    })
    //debugger
    this.setState({
      toolsets,
      events
    })
  }
  
  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  checkScroll = () => {
    if (this.props.checkScrollBack) {
      this.props.checkScrollBack()
    }
    const visibleToolsetCount = Math.min(this.state.visibleToolsetCount + 10, this.props.toolsets.length)
    this.setState({
      visibleToolsetCount
    })
  }

  onSearch = searchTerm => {
  }

    
  renderContent() {
    const output = [this.renderListView()]
    return output
  }

  setFileChooser = ref => {
    this.fileChooser = ref
  }
  
  renderListView(bare) {
    let toolsets
    let events
    if (this.props.folder) {
      toolsets = this.state.toolsets || []
      events = this.state.events
    } else {
      toolsets = this.props.toolsets || []
      events = toolsets.map((elem) => {
        const { id, lastUpdated } = elem
      const start = new Date(lastUpdated)
        return {
          id,
        start,
          text: '',
          daily: elem
        }
      })
    }
    let filt
    switch (this.state.calView) {
      case 'recent':
        break
      case 'day':
        filt = job => {
          const { lastUpdated } = job
          return startOfDay(this.state.calDay) < lastUpdated && endOfDay(this.state.calDay) > lastUpdated
        }
        break
      case 'month':
        filt = job => {
          const { lastUpdated } = job
          return startOfMonth(this.state.calDay) < lastUpdated && endOfMonth(this.state.calDay) > lastUpdated
        }
        break
    }
    if (filt) {
      toolsets = toolsets.filter(filt)
    }
    let className= 'toolsetTopLine'
    if (this.props.select) {
      className += ' toolsetTopLineSelect'
    }
    const onDrop = event => {
      handleDataTransfer(event, event.dataTransfer)
    }
    const handleDataTransfer = async (event, transfer) => {
      const models = []//this.modelsView.getSelectedModelIds()
      if (transfer.files.length > 0) {
        event.preventDefault();
        const parent = this.props.folder ? this.props.folder.id : undefined
        for (const file of transfer.files) {
          await this.props.uploadToolset({parent, file, models})
        }
        return true;
      } else {
        const uriList = transfer.getData('text/uri-list')
        if (uriList) {
          event.preventDefault()
          // Split the URI list by newline to handle multiple URLs
          const urls = uriList.split('\n').filter(url => url.trim() !== '')
          console.log('URLs detected:', urls)
          for (const url of urls) {
            await this.props.uploadToolset({url, models})
          }
        }
      }
    }
    const onDragToolset = toolset => {
      selectedToolset = toolset
    }
    const onDropToolset = async toolset => {
      const src = this.state.selectedToolset
      const target = toolset
      if (src !== target) {
        if (target.props.toolset.isFolder) {
          if (await this.props.addParent({child: src.props.toolset, parent: target.props.toolset.id})) {
            this.openFolder(target.props.toolset)
          }
        }
      }
    }
    let fileChooser
    let actions = []
    if (this.props.newButton) {
      actions.push(
      {
        label: "New Toolset",
        button: close => this.props.newButton(async () => {
          await this.createNewToolset()
          close()
        })
      })
    }
    actions.push(
      {
        label: "New Folder",
        icon: NewFolder,
        action: this.createToolsetFolder,
      }
    )
    if (this.props.uploadToolset) {
      fileChooser = <div className='fileMenu'><FileChooser
                                                onCreate={this.setFileChooser}
                                                fileTypes=".json"
                                                handleDataTransfer={handleDataTransfer}/></div>
      actions = actions.concat([
        {
          label: "Import File",
          action: () => this.fileChooser.chooseFile(),
          legacyIconSize: true,
          icon: OpenFile
        },
        {
          label: "Import from HuggingFace",
          action: () => this.openHuggingFace(),
          icon: HF
        }])
    }
    if (this.props.clipboard) {
      const paste = async () => {
        await this.props.addToolsetParent(
          { parent: this.props.folder ? this.props.folder.id: undefined,
            child: this.props.clipboard
          }
        )
      }
      actions.push({
        label: "Paste",
        icon: Paste,
        action: paste
      })
    }
    const renderToolsets = (toolsets, openDetail) => {
      return <div className='chatGptToolsetsMenuToolsets' onDrop={onDrop}>
               {
                 toolsets.map(toolset => {
                   let selectToolset
                   if (this.props.select) {
                     selectToolset = () => this.props.select(toolset)
                   }
                   const openToolset = async () => {
                     this.openToolset(toolset)
                   }
                   const saveTitle = async (title) => {
                     toolset.name = title

                     //debugger
                     await this.saveToolset(toolset)
                   }
                   const trash = async () => {
                     await this.deleteToolset(toolset)
                     this.forceUpdate()
                   }
                   return <Toolset
                            renderTask={this.props.renderTask}
                            emptyTitle={this.props.emptyTitle}
                            openDetail={openDetail}
                            onDrag={onDragToolset}
                            onDrop={onDropToolset}
                            folder={this.props.folder}
                            key={toolset.id}
                            select={selectToolset}
                            toolset={toolset}
                            save={saveTitle}
                            copy={this.props.copy}
                            cut={this.props.cut}
                            edit={this.state.openPrompt ? null: openToolset}
                            open={openToolset}
                            trash={trash}/>
                 })
               }
             </div>
    }
    if (bare) {
      return renderToolsets(toolsets.filter(x => !x.isFolder))
    }
    let detail
    if (isDesktop() && !this.state.subpage && this.props.openDetail) {
      let title = (this.props.folder && this.props.folder.name) || ''
      detail = this.props.openDetail(() => <div key={(this.props.folder && this.props.folder.id) || 'main'} className='desktopToolsetDetails'><div className='desktopToolsetDetailsTitle'>{title}</div>{renderToolsets(toolsets.filter(x => !x.isFolder))}</div>)
      toolsets = toolsets.filter(x => x.isFolder)
    }
    let openDetail = this.props.openDetail
    return <div className={'chatGptToolsetsMenu'} ref={this.setScrollRef}>
             <div className={className}>
               <ActionMenu className='toolsetActions' position="bottom left"  actions={actions}/>
               <SearchField onSearch={this.onSearch} me={this.props.me} menu={fileChooser}/>
             </div>
              <div className='toolsetsCal'>
               <Calendar onPageChange={this.onPageChange} onViewChange={this.onViewChange} events={events} onDayChange={this.onDayChange} initialView={'recent'} viewSelection={['recent', 'day', 'month']} />
              </div>
             {renderToolsets(toolsets, openDetail)}
             {detail}
           </div>
  }
  

}


