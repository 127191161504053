import React, { Component } from 'react'
import Popup from 'reactjs-popup';
import MenuDown from '../../assets/Icons/MenuDown.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import { SimpleButton } from '../ChatGPT'
import './ActionMenu.css'

export class ActionMenu extends Component {

  setRef = ref => {
    this.ref = ref
  }

  setPopupRef = popupRef => {
    this.popup = popupRef
    if (popupRef) {
      this.popupContent = document.getElementsByClassName('popup-content')[0]
      document.addEventListener("mousewheel", this.handleMouseWheel)
    } else {
      document.removeEventListener("mousewheel", this.handleMouseWheel)
    }
  }

  handleMouseWheel = event => {
    if (this.popupContent && !this.popupContent.contains(event.target)) {
      this.popup.close()
    }
  }

  render() {
    let { actions } = this.props
    const trigger = <div className={this.props.className}><SimpleButton icon={MenuDown} action={() => this.forceUpdate()}/></div>
    const onOpen = (isOpen) => {}
    let maxHeight
    let position = "bottom right"
    const ref = this.ref
    let className = 'editMenu'
    if (this.props.className) {
      className += ' ' + this.props.className
    }
    if (ref) {
      const threshold = window.innerHeight * 0.66
      const rect = ref.getBoundingClientRect()
      if (rect.top  > threshold) {
        position = "top right"
        className += ' editMenuTop'
      }
      //console.log({position, ref, top: rect.top, threshold})
    }
    position = this.props.position || position
    if (position.indexOf("left") > 0) {
      className += ' actionMenuLeft'
    }
    const renderMenu = (close) => {
      const callAction = action => async () => {
        await action()
        close()
      }
      return <div className={className}>
               {
                 actions.map(x => {
                   const { button, icon, action, label, legacyIconSize } = x
                   return button ? button(close) : <SimpleButton legacyIconSize={legacyIconSize} label={label} icon={icon} action={callAction(action)}/>
                 })
               }
      </div>

   }
    return <div className='editMenuPopup' ref={this.setRef}>
             <Popup ref={this.setPopupRef} closeOnDocumentClick trigger={trigger} position={position}>
               {close => renderMenu(close)}
             </Popup>
           </div>
  }
}

