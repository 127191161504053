import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { isDesktop, isMobile } from '../../classes/Platform.js'
import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer, CardElement, PaymentElement} from '@stripe/react-stripe-js'
import './Purchase.css'
let _stripePromise

const stripeKeys = {
  test: 'pk_test_dkomIQGNwr7Ziw4yvd6RJNxi006vne2jpJ',

}

const getEnv = () => 'test'

const stripePromise = () => {
  return _stripePromise ? _stripePromise : _stripePromise = loadStripe(stripeKeys[getEnv()])
}

export class StripePaymentMethodInput extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.onCreate(this)
  }

  renderCardElement() {
    let color = 'gray'
    let background = 'white'
    const CARD_OPTIONS = {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: 'white',
          color: 'white',
          fontWeight: 500,
          fontSize: '16px',
          caretColor: 'white',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: 'white',
          },
        },
        webkitAutoFill: {
          color: 'white',
          caretColor: 'white',
          backgroundColor: background
        },
        invalid: {
          iconColor: '#ffc7ee',
          color: '#ffc7ee',
        },
      },
    }
    return <div className='bnCardElement'><CardElement onChange={this.onChangePaymentMethod} options={CARD_OPTIONS}/></div>
  }

  onChangePaymentMethod = e => {
    this.setState({
      paymentMethodError: null
    })
    //console.log(e)
  }


  onChangePaymentElement = e => {
    //console.log('payment element', e)
    const updates = {
      paymentComplete: e.complete,
      paymentType: e.value ? e.value.type : '',
      paymentMethodError: ''
    }
    this.setState(updates)
    if (this.props.onStatusChange) this.props.onStatusChange(updates)
  }

  apply = async () => {
    if (this.props.setupIntent) {
      const elements = this.elements
      const result = await this.stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: "https://llmao-bf512.appspot.com",
        },
        redirect: 'if_required'
      })
      return result
    } else if (this.props.paymentIntent) {
      const elements = this.elements
      const result = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "https://llmao-bf512.appspot.com",
        },
        redirect: 'if_required'
      })
      debugger
      return result
    }
    return {}
  }

  renderPaymentMethodInput(clientSecret) {
    if (clientSecret) {
      const options = {
        clientSecret,
        appearance: {
          variables: {
            colorPrimary: 'white',
            colorTextSecondary: 'rgb(120, 120, 120)',
            colorBackground: 'transparent',
            colorText: 'white',
            fontFamily: 'apparat, sans-serif',
            fontWeightNormal: '550',
            borderRadius: '0px',
            colorDanger: 'white',
            colorTextPlaceholder: "#888888"
          },
          rules: {
            '.Label': {
              color: 'white',
              fontWeight: 'bold',
            },
            '.Input': {
              color: '#333333',
              backgroundColor: '#e7e7e7',
            }
          }
        }
      }
      return <Elements stripe={stripePromise()} options={options}>
               <ElementsConsumer>
                 {({elements, stripe}) => {
                   this.stripe = stripe
                   this.elements = elements;
                   if (false && isMobile()) {
                     return this.renderCardElement()
                   }
                   return <PaymentElement onChange={this.onChangePaymentElement}/>
                 }
                 }
               </ElementsConsumer>
             </Elements>
    }
    return null
  }

  render() {
    let clientSecret
    if (this.props.paymentIntent) {
      clientSecret = this.props.paymentIntent.client_secret
      console.log("paymentIntent", this.props.paymentIntent)
    }
    console.log("clientSecret", clientSecret)
    return <div className='bnPaymentMethodInputContainer'>
             <div className='bnPaymentMethodInput'>
               {this.renderPaymentMethodInput(clientSecret)}
             </div>
           </div>
  }
}
